import React, { Fragment, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { BodyText, BodyTitle } from '../../../../../../components/ReusableComponents/Text/Text';
import IncrementDecrementInput from './IncrementDecrementInput';
import { useSelector } from 'react-redux';
import { PriceAdjustContext } from '../../../../PriceAdjustment';

const FaroutTitle = styled(BodyTitle)`
  align-self: flex-start;
  margin: 0 0 20px 0;
`;

const FarOutInputDiv = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 0 0;
  position: relative;
`;

const FarOutInputDivSec = styled(FarOutInputDiv)`
  margin: 8px 0 16px 0;
`;

const BodyTextLabel = styled(BodyText)`
  font-size: 18px;
  margin: 0 0 0 0px;
`;

const InputDiv = styled.div`
  width: 160px;
  max-width: 190px;
  height: fit-content;
`;

const FarOut = () => {
  const { propertyPricingRules } = useSelector(({ property }) => property);
  const {farOutPrice, setFarOutPrice, activeProperty} = useContext(PriceAdjustContext);

  useEffect(() => {
    setFarOutPrice((prev) => ({
      ...prev,
      [activeProperty]: {
        ...prev?.[activeProperty],
        faroutStartDay: propertyPricingRules?.faroutStartDay,
        faroutPrice: propertyPricingRules?.faroutPrice,
      }
    }));

  }, [propertyPricingRules, activeProperty, setFarOutPrice])


  const currencySymbol = localStorage.getItem("currency");

  const onFarOutDayChange = (value) => {

    setFarOutPrice((prev) => ({
      ...prev,
      [activeProperty]: {
        ...prev?.[activeProperty],
        faroutStartDay: value,
      }
    }));
  };

  const onFarOutPriceChange = (value) => {

    setFarOutPrice((prev) => ({
      ...prev,
      [activeProperty]: {
        ...prev?.[activeProperty],
        faroutPrice: value,
      }
    }));
  };

  return (
    <Fragment>
      <FaroutTitle>
        Far Out Price
      </FaroutTitle>
      <FarOutInputDiv>
        <BodyTextLabel>
          Far Out Start Day
        </BodyTextLabel>
        <InputDiv>
          <IncrementDecrementInput allowEmpty={true} inputValue={farOutPrice?.[activeProperty]?.faroutStartDay} onInputChange={onFarOutDayChange}/>
        </InputDiv>
      </FarOutInputDiv>
      <FarOutInputDivSec>
        <BodyTextLabel>
          Min Price {`(${currencySymbol})`}
        </BodyTextLabel>
        <InputDiv>
          <IncrementDecrementInput allowEmpty={true} inputValue={farOutPrice?.[activeProperty]?.faroutPrice} onInputChange={onFarOutPriceChange}/>
        </InputDiv>
      </FarOutInputDivSec>
    </Fragment>
  )
}

export default FarOut;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';
import { useDispatch } from 'react-redux';
import {
  showAuthLoader,
  showAuthMessage,
  updateUserPMS } from 'appRedux/actions';


const HospitableButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px;
`;

const Hospitable = ({signupString, setAuthMenuFunc}) => {
  const [externalPopup, setExternalPopup] = useState(false);
  const dispatch = useDispatch();


  const authorizeHospitable = () => {
    const winWidth = 600;
    const winHeight = 700;
    const left = window.screenX + (window.outerWidth - winWidth) / 2;
    const top = window.screenY + (window.outerHeight - winHeight) / 2.5;
    const title = `Authorize QuibbleRM`;
    const url = `https://auth.hospitable.com/oauth/authorize?client_id=${process.env.REACT_APP_HOSPITABLE_OAUTH_CLIENT_ID}&response_type=code`;
    const popup = window.open(url, title, `popup=true,width=${winWidth},height=${winHeight},left=${left},top=${top}`);
    setExternalPopup(popup);
  };

  useEffect(() => {
    if (!externalPopup ) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }

      if (externalPopup.closed !== false) {
          window.clearInterval(timer);
      }

      let currentUrl;

      try {
        currentUrl = externalPopup.location.href;
      } catch (err) {}

      if (!currentUrl || currentUrl === 'about:blank') {
        return;
      }

      const searchParams = new URL(currentUrl).searchParams;

      const code = searchParams.get('code');
      const error = searchParams.get('error');
      const errorDescription = searchParams.get('error_description');

      if (code) {
        dispatch(showAuthLoader());
        dispatch(updateUserPMS({
          code: code,
          client_id: process.env.REACT_APP_HOSPITABLE_OAUTH_CLIENT_ID,
          string: signupString,
          pms: 'Hospitable',
          type: 'pms_detail',
        }));
        setAuthMenuFunc('finish');
      } else if (error || errorDescription) {
        dispatch(showAuthMessage({ status: false, message: errorDescription || error }))
      } else {
        dispatch(showAuthMessage({ status: false, message: 'No code' }))
      }

      setExternalPopup(null);
      timer && clearInterval(timer);
      externalPopup.close();
    }, 500)
  },[externalPopup, dispatch, signupString, setAuthMenuFunc])



  return (
    <HospitableButton onClick={authorizeHospitable}>
      Sign in with Hospitable
    </HospitableButton>
  )
}

export default Hospitable;
import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import {property} from "../../services/property";
import { markets } from "../../services/markets";
import {
  USER_PROPERTY_LIST,
  GET_PROPERTY_DETAIL,
  UPDATE_PROPERTY_STATUS,
  USER_PROPERTY_YEARLY_DATA,
} from "constants/ActionTypes";
import {
  setPropertyDetail,
  updatePropertyList,
  showPropertyMessage,
  setCurrentAndLastYearData,
  updatePropertyLists,
  updatePropertiesReservations,
  updateCityIds,
  updateUserDataSync,
  // getPropertyGroupsSuccess,
  // getPropertyGroupByIdSuccess,
  // createPropertyGroupSuccess,
} from "../../appRedux/actions/Property";
import { showUserErrorMessages } from "../actions";
import { DOWNLOAD_OWNER_REPORT,
  DOWNLOAD_OWNER_REPORT_SUCCESS,
  GET_CALENDAR_LISTINGS,
  GET_CALENDAR_LISTINGS_SUCCESS,
  GET_CITY_ID, GET_PROPERTIES_LISTS,
  GET_PROPERTIES_RESERVATIONS,
  GET_PROPERTY_CALENDAR,
  GET_PROPERTY_CALENDAR_SUCCESS,
  GET_PROPERTY_DETAILS,
  GET_PROPERTY_DETAILS_SUCCESS,
  GET_PROPERTY_PRICING,
  GET_PROPERTY_PRICING_SUCCESS,
  GET_SYNC_DATA,
  REMOVE_BUILDING_TAGS,
  UPDATE_BUILDING_TAGS,
  UPDATE_DISTRIBUTION_CHANNEEL,
  UPDATE_PROPERTY_LOGS,
  GET_PROPERTY_OVERVIEW_METRICS,
  GET_PROPERTY_OVERVIEW_METRICS_SUCCESS,
  GET_PROPERTY_TABLE_METRICS,
  GET_PROPERTY_TABLE_METRICS_SUCCESS,
  GET_PROPERTY_MODEL_SUCCESS,
  GET_PROPERTY_MODEL,
  GET_PROPERTY_COMPETITORS_SUCCESS,
  GET_PROPERTY_COMPETITORS,
  GET_PROPERTY_ANALYTICS,
  GET_PROPERTY_ANALYTICS_SUCCESS,
  GET_PROPERTY_PRICING_RULES,
  GET_PROPERTY_PRICING_RULES_SUCCESS,
  GET_PROPERTY_QUEUE,
  GET_PROPERTY_QUEUE_SUCCESS,
  SET_WORKING_QUEUE,
  SET_WORKING_QUEUE_DATA,
  GET_PROPERTY_GROUPS,
  GET_PROPERTY_GROUP_BY_ID,
  GET_PROPERTY_GROUPS_SUCCESS,
  GET_PROPERTY_GROUPS_LISTINGS,
  GET_PROPERTY_GROUPS_LISTINGS_SUCCESS,
  GET_PROPERTY_GROUP_BY_ID_SUCCESS,
  GET_ACTIONABLE_QUEUE,
  GET_ACTIONABLE_QUEUE_SUCCESS,
  GET_PROPERTY_OVERVIEW_CHART_SUCCESS,
  GET_PROPERTY_OVERVIEW_CHART,
  GET_PROPERTY_SCOUT_SEARCH_SUCCESS,
  GET_PROPERTY_SCOUT_SEARCH,
  GET_PROPERTY_SCOUT_PROPERTIES,
  GET_PROPERTY_SCOUT_PROPERTIES_SUCCESS
} from "../../constants/ActionTypes";

import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { message } from "antd";

/** Request to get property listing */
const getUserPropertyRequest = async (data) =>
  await property.list(data)
    .then(result => result)
    .catch(error => error);

function* getUserPropertyData({payload}) {
  try {
    let propertyList = yield call(getUserPropertyRequest, payload);
    yield put(updatePropertyList(propertyList.data));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}

export function* toUserPropertyList() {
  yield takeEvery(USER_PROPERTY_LIST, getUserPropertyData);
}
/** End :: Request to get property listing */


const getUserSyncRequest = async (data) =>
  await property
    .syncData(data)
    .then((result) => {
      toast.success('Data sync in progress...');
      return result
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "Unable to complete request");
      return error
    });

/** function to get user dashboard info */
function* getUserSyncInfo({ payload }) {
  try {
    const userDashboard = yield call(getUserSyncRequest, payload);
    yield put(updateUserDataSync(userDashboard.data));
  } catch (error) {
    console.log(error);
    yield put(showPropertyMessage(error));
  }
}

/** Export function user dashboard */
export function* userSyncInfo() {
  yield takeEvery(GET_SYNC_DATA, getUserSyncInfo);
}




/** Request to get property listing */
const getPropertyListsRequest = async (data) =>
  await property.propertyLists(data)
    .then(result => result)
    .catch(error => error);

function* getPropertyListsData({payload}) {
  try {
    let propertyList = yield call(getPropertyListsRequest, payload);
    yield put(updatePropertyLists(propertyList.data));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}

export function* toPropertyLists() {
  yield takeEvery(GET_PROPERTIES_LISTS, getPropertyListsData);
}
/** End :: Request to get property listing */


/** Request to get City Id */
const getCityIdsDataRequest = async (data) =>
  await property.cityId(data)
    .then(result => result)
    .catch(error => error);

function* getCityIdsData({payload}) {
  try {
    let propertyList = yield call(getCityIdsDataRequest, payload);
    yield put(updateCityIds(propertyList.data));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}

export function* toCityIdsLists() {
  yield takeEvery(GET_CITY_ID, getCityIdsData);
}
/** End :: Request to get City Id */



/** Request to get yearly data analysis */
const getUserPropertyAnalysisRequest = async (data) =>
  await property.dataAnalysis(data)
    .then(result => result)
    .catch(error => error);

function* getUserPropertyAnalysisData({payload}) {
  try {
    let propertyData = yield call(getUserPropertyAnalysisRequest, payload);
    yield put(setCurrentAndLastYearData(propertyData.data));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}

export function* toUserPropertyAnalysis() {
  yield takeEvery(USER_PROPERTY_YEARLY_DATA, getUserPropertyAnalysisData);
}
/** End :: Request to get yearly data analysis */




/** Start :: Request to update property status */
const updatePropertyStatusRequest = async (data) =>
  await property.update(data)
    .then(result => result)
    .catch(error => error);

function* updatePropertyStatus({payload}) {
  try {
    const propertyStatus = yield call(updatePropertyStatusRequest, payload);
    yield put(showPropertyMessage(propertyStatus.data.message));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}

export function* toUpdatePropertyStatus() {
  yield takeEvery(UPDATE_PROPERTY_STATUS, updatePropertyStatus);
}
/** End :: Request to update property status */


/** Start :: Request to get property detail */
const getPropertyDetailRequest = async (data) =>
  await property.detail(data)
    .then(result => result)
    .catch(error => error);

function* getPropertyDetail({payload}) {
  try {
    const propertyDetail = yield call(getPropertyDetailRequest, payload);
    yield put(setPropertyDetail(propertyDetail.data.result ?? {}));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}

export function* toGetPropertyDetail() {
  yield takeEvery(GET_PROPERTY_DETAIL, getPropertyDetail);
}
/** End :: Request to get property detail */

/** Request to get properties reservation listing */
const getPropertiesReservationRequest = async (data) =>
  await property.propertiesReservation(data)
    .then(result => result)
    .catch(error => error);
function* getPropertiesReservationData({payload}) {
  try {
    let propertyList = yield call(getPropertiesReservationRequest, payload);
    yield put(updatePropertiesReservations(propertyList.data));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}
export function* toPropertiesReservationList() {
  yield takeEvery(GET_PROPERTIES_RESERVATIONS, getPropertiesReservationData);
}
/** End :: Request to get properties reservation listing */

/** Start :: Update Property Logs */
const updatePropertyLogsRequest = async (data) =>
  await property.addLogs(data)
    .then(result => result)
    .catch(error => error);

function* updatePropertyLogs({payload}) {
  try {
    const res = yield call(updatePropertyLogsRequest, payload);
    yield put(showPropertyMessage(res.data.message));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}

export function* toUpdatePropertyLogs() {
  yield takeEvery(UPDATE_PROPERTY_LOGS, updatePropertyLogs);
}
/** End :: Update Property Logs */

/** Start :: Update Property Logs */
const updateBuildingTagsRequest = async (data) =>
  await property.updateBuildingTags(data)
    .then(result => result)
    .catch(error => error);

function* updateBuildingTags({payload}) {
  try {
    const res = yield call(updateBuildingTagsRequest, payload);
    yield put(showPropertyMessage(res.data.message));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}

export function* toUpdateBuildingTags() {
  yield takeEvery(UPDATE_BUILDING_TAGS, updateBuildingTags);
}
/** End :: Update Property Logs */


/** Start :: Update Property Logs */
const removeBuildingTagsRequest = async (data) =>
  await property.removeBuildingTags(data)
    .then(result => result)
    .catch(error => error);

function* removeBuildingTags({payload}) {
  try {
    const res = yield call(removeBuildingTagsRequest, payload);
    yield put(showPropertyMessage(res.data.message));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}

export function* toRemoveBuildingTags() {
  yield takeEvery(REMOVE_BUILDING_TAGS, removeBuildingTags);
}
/** End :: Update Property Logs */

/** Start :: Generate Owner Report */
function* downloadOwnerReport({ payload }) {
  try {
    const res = yield call(property.downloadOwnerReport, payload);
    const blob = new Blob(([res.data]), {type: 'application/pdf'});
    const filename = `${payload?.property?.name?.toUpperCase()}_${payload?.month?.toUpperCase()}_${payload?.year}_OWNER_REPORT_${Date.now()}.pdf`
    saveAs(blob, filename);
    yield put({ type: DOWNLOAD_OWNER_REPORT_SUCCESS });
    yield put(showPropertyMessage('Successfully downloaded owner report'));
    yield put(showPropertyMessage('')); // Reset to default
  } catch (error) {
    yield put(showPropertyMessage('An error occured while downloading the owner report'));
  }
}

export function* toDownloadOwnerReport() {
  yield takeEvery(DOWNLOAD_OWNER_REPORT, downloadOwnerReport);
}
/** End :: Generate Owner Report */

/** Begining Chanel dBistribution Udate Section */
const updateChannelListingIds = async (data) =>
  await property.updateChannelListings(data)
    .then(result => result)
    .catch(error => error);

function* updateChanelIdx({payload}) {
  try {
    const res = yield call(updateChannelListingIds, payload);
    yield put(showPropertyMessage(res.data.message));
  } catch (error) {
    yield put(showPropertyMessage(error));
  }
}

export function* toUpdateChannelListingIds() {
    yield takeEvery(UPDATE_DISTRIBUTION_CHANNEEL, updateChanelIdx);
}

function* getCalendarListings({payload}){
  try {
    const calendarList = yield call(property.calendar, { params:{...payload} });
    yield put({ type: GET_CALENDAR_LISTINGS_SUCCESS, payload: calendarList });
  } catch (err) {
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving calendar listings.',
      })
    );
  }
}

export function* getCalendarListingsWatcher() {
  yield takeLatest(GET_CALENDAR_LISTINGS, getCalendarListings);
}

function* getPropertyCalendar({payload}){
  try {
    const propertyCalendar = yield call(property.calendar, { params:{...payload} });
    yield put({ type: GET_PROPERTY_CALENDAR_SUCCESS, payload: propertyCalendar });
  } catch (err) {
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving calendar listings.',
      })
    );
  }
}

export function* getPropertyCalendarWatcher() {
  yield takeLatest(GET_PROPERTY_CALENDAR, getPropertyCalendar);
}

function* getPropertyPricing({payload}){
  try {
    const propertyPricing = yield call(property.propertyPricing, { params:{...payload} });
    yield put({ type: GET_PROPERTY_PRICING_SUCCESS, payload: propertyPricing });
  } catch (err) {
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving calendar listings.',
      })
    );
  }
}

export function* getPropertyPricingWatcher() {
  yield takeLatest(GET_PROPERTY_PRICING, getPropertyPricing);
}

function* getPropertyDetails({payload}){
  try {
    const propertyDetails = yield call(property.getPropertyDetails, { ...payload });
    yield put({ type: GET_PROPERTY_DETAILS_SUCCESS, payload: propertyDetails });
  } catch (err) {
    yield put({ type: GET_PROPERTY_DETAILS_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving property details.',
      })
    );
  }
}

export function* getPropertyDetailsWatcher() {
  yield takeLatest(GET_PROPERTY_DETAILS, getPropertyDetails);
}

function* getPropertyOverviewMetrics({payload}){
  try {
    const propertyOverviewMetrics = yield call(property.getPropertyOverviewMetrics, { ...payload });
    yield put({ type: GET_PROPERTY_OVERVIEW_METRICS_SUCCESS, payload: propertyOverviewMetrics });
  } catch (err) {
    yield put({ type: GET_PROPERTY_OVERVIEW_METRICS_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving overview metrics.',
      })
    );
  }
}

export function* getPropertyOverviewMetricsWatcher() {
  yield takeLatest(GET_PROPERTY_OVERVIEW_METRICS, getPropertyOverviewMetrics);
}

function* getPropertyTableMetrics({payload}){
  try {
    const propertyTableMetrics = yield call(property.getPropertyTableMetrics, { ...payload });
    yield put({ type: GET_PROPERTY_TABLE_METRICS_SUCCESS, payload: propertyTableMetrics });
  } catch (err) {
    yield put({ type: GET_PROPERTY_TABLE_METRICS_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving property table.',
      })
    );
  }
}

export function* getPropertyTableMetricsWatcher() {
  yield takeLatest(GET_PROPERTY_TABLE_METRICS, getPropertyTableMetrics);
}

function* getPropertyModel({payload}){
  try {
    const propertyModel = yield call(property.getPropertyModel, { ...payload });
    yield put({ type: GET_PROPERTY_MODEL_SUCCESS, payload: propertyModel });
  } catch (err) {
    yield put({ type: GET_PROPERTY_MODEL_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving property model.',
      })
    );
  }
}

export function* getPropertyModelWatcher() {
  yield takeLatest(GET_PROPERTY_MODEL, getPropertyModel);
}

function* getPropertyCompetitors({payload}){
  try {
    const propertyCompetitors = yield call(property.getPropertyCompetitors, { ...payload });
    yield put({ type: GET_PROPERTY_COMPETITORS_SUCCESS, payload: propertyCompetitors });
  } catch (err) {
    yield put({ type: GET_PROPERTY_COMPETITORS_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving property competitors.',
      })
    );
  }
}

export function* getPropertyCompetitorsWatcher() {
  yield takeLatest(GET_PROPERTY_COMPETITORS, getPropertyCompetitors);
}

function* getPropertyAnalytics({payload}){
  try {
    const params = payload ?  {...payload?.params,  includeQuadrants: true, view: 'property'} : {includeQuadrants: true, view: 'property'};

    const propertyAnalytics = yield call(markets.getMarketAnalytics, { params: params });
    yield put({ type: GET_PROPERTY_ANALYTICS_SUCCESS, payload: propertyAnalytics });
  } catch (err) {
    yield put({ type: GET_PROPERTY_ANALYTICS_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving property analytics.',
      })
    );
  }
}

export function* getPropertyAnalyticsWatcher() {
  yield takeLatest(GET_PROPERTY_ANALYTICS, getPropertyAnalytics);
}

function* getPropertyPricingRules({payload}){
  try {
    const propertyPricingRules = yield call(property.getPropertyPricingRules, { ...payload });
    yield put({ type: GET_PROPERTY_PRICING_RULES_SUCCESS, payload: propertyPricingRules });
  } catch (err) {
    yield put({ type: GET_PROPERTY_PRICING_RULES_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving pricing rules.',
      })
    );
  }
}

export function* getPropertyPricingRulesWatcher() {
  yield takeLatest(GET_PROPERTY_PRICING_RULES, getPropertyPricingRules);
}

function* getPropertyQueue({payload}){
  try {
    const workingQueue = yield call(property.getPropertyQueue, { ...payload });
    yield put({ type: GET_PROPERTY_QUEUE_SUCCESS, payload: workingQueue });
  } catch (err) {
    yield put({ type: GET_PROPERTY_QUEUE_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving property queue.',
      })
    );
  }
}

export function* getPropertyQueueWatcher() {
  yield takeLatest(GET_PROPERTY_QUEUE, getPropertyQueue);
}

function* getActionableQueue({payload}){
  try {
    const actionableQueue = yield call(property.getActionableQueue);
    yield put({ type: GET_ACTIONABLE_QUEUE_SUCCESS, payload: actionableQueue });
  } catch (err) {
    yield put({ type: GET_ACTIONABLE_QUEUE_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving property queue.',
      })
    );
  }
}

export function* getActionableQueueWatcher() {
  yield takeLatest(GET_ACTIONABLE_QUEUE, getActionableQueue);
}


function* setPropertyQueue({payload}){
    yield put({ type: SET_WORKING_QUEUE_DATA, payload: payload });
}

export function* setPropertQueue() {
  yield takeLatest(SET_WORKING_QUEUE, setPropertyQueue);
}


// Property groups
function* getPropertyGroups(payload) {
  try {
    const propertyGroups = yield call(property.getPropertyGroups, { ...payload });
    yield put({ type: GET_PROPERTY_GROUPS_SUCCESS, payload: propertyGroups });
  } catch (error) {
    yield put(showUserErrorMessages({
      status: false,
      message: 'Error retrieving property groups.',
    }));
  }
}

function* getPropertyGroupById({ payload }) {
  try {
    const propertyGroupById = yield call(property.getPropertyGroups, { ...payload });
    yield put({ type: GET_PROPERTY_GROUP_BY_ID_SUCCESS, payload: propertyGroupById });
  } catch (error) {
    yield put(showUserErrorMessages({
      status: false,
      message: 'Error retrieving property groups.',
    }));
  }
}

function* getPropertyGroupsListings(payload) {
  try {
    const propertyGroupsListings = yield call(property.getPropertyGroupsListings, { ...payload });
    yield put({ type: GET_PROPERTY_GROUPS_LISTINGS_SUCCESS, payload: propertyGroupsListings });
  } catch (error) {
    yield put(showUserErrorMessages({
      status: false,
      message: 'Error retrieving property groups listings.',
    }));
  }
}

export function* watchGetPropertyGroups() {
  yield takeEvery(GET_PROPERTY_GROUPS, getPropertyGroups);
}

export function* watchGetPropertyGroupById() {
  yield takeEvery(GET_PROPERTY_GROUP_BY_ID, getPropertyGroupById);
}

export function* watchGetPropertyGroupsListings() {
  yield takeEvery(GET_PROPERTY_GROUPS_LISTINGS, getPropertyGroupsListings);
}

function* getPropertyOverviewChart({payload}){
  try {
    const propertyOverviewChart = yield call(property.getPropertyAnalytics, { ...payload });
    yield put({ type: GET_PROPERTY_OVERVIEW_CHART_SUCCESS, payload: propertyOverviewChart });
  } catch (err) {
    yield put({ type: GET_PROPERTY_OVERVIEW_CHART_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving overview chart',
      })
    );
  }
}

export function*getPropertyOverviewChartWatcher() {
  yield takeLatest(GET_PROPERTY_OVERVIEW_CHART,getPropertyOverviewChart);
}

function* getPropertyScoutSearch({ payload }) {
  try {
    if (payload.clear) {
      yield put({ type: GET_PROPERTY_SCOUT_SEARCH_SUCCESS, payload: [] });
      return;
    }; 
      
    const propertyScoutSerach = yield call(property.propertyScoutSearch, { ...payload });
    yield put({ type: GET_PROPERTY_SCOUT_SEARCH_SUCCESS, payload: propertyScoutSerach });
  } catch (error) {
    message.error(`Error retrieving place from ${payload?.params?.q} , try another keyword`, 3);
  }
}

export function* getPropertyScoutSearchWatcher() {
  yield takeLatest(GET_PROPERTY_SCOUT_SEARCH,getPropertyScoutSearch);
}

function* getPropertyScoutProperties({ payload }) {
  try {
    const propertyScoutProperties = yield call(property.propertyScoutProperties, { ...payload });
    yield put({ type: GET_PROPERTY_SCOUT_PROPERTIES_SUCCESS, payload: propertyScoutProperties });
  } catch (error) {
    message.error(`error retrieving properties`, 3);
  }
}

export function* getPropertyScoutPropertiesWatcher() {
  yield takeLatest(GET_PROPERTY_SCOUT_PROPERTIES, getPropertyScoutProperties);
}



/** Export all */
export default function* rootSaga() {
  yield all([
    fork(toUpdatePropertyStatus),
    fork(toUserPropertyList),
    fork(toUserPropertyAnalysis),
    fork(toGetPropertyDetail),
    fork(toUpdatePropertyLogs),
    fork(toUpdateBuildingTags),
    fork(toRemoveBuildingTags),
    fork(toUpdateChannelListingIds),
    fork(toDownloadOwnerReport),
    fork(toPropertyLists),
    fork(toPropertiesReservationList),
    fork(toCityIdsLists),
    fork(userSyncInfo),
    fork(getCalendarListingsWatcher),
    fork(getPropertyCalendarWatcher),
    fork(getPropertyPricingWatcher),
    fork(getPropertyDetailsWatcher),
    fork(getPropertyOverviewMetricsWatcher),
    fork(getPropertyTableMetricsWatcher),
    fork(getPropertyModelWatcher),
    fork(getPropertyCompetitorsWatcher),
    fork(getPropertyAnalyticsWatcher),
    fork(getPropertyPricingRulesWatcher),
    fork(getPropertyQueueWatcher),
    fork(setPropertQueue),
    fork(watchGetPropertyGroups),
    fork(watchGetPropertyGroupById),
    fork(watchGetPropertyGroupsListings),
    fork(getActionableQueueWatcher),
    fork(getPropertyOverviewChartWatcher),
    fork(getPropertyScoutSearchWatcher),
    fork(getPropertyScoutPropertiesWatcher)
  ]);
} 

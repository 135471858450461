import React from "react";
import { BodyText, BodyTitle } from "../../../../components/ReusableComponents/Text/Text";
import styled from "styled-components";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { HALF_MONTH_NAME } from "../../../../constants/GlobalConstant";

const MetricProjectionDiv = styled.div`
  width: ${({$width}) => $width};
  height: 792px;
  background: white;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #E2E2E2;


  display: flex;
  flex-direction: column;
  gap: 18px;

  
`;

const EstimatedDiv = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
`;

const MetricDiv = styled.div`
  width: ${({$width}) => $width};
  height: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid #D9D9D9;
`;

const MetricValue = styled(BodyTitle)`
  color: ${({$color}) => $color};
  margin: 0px;
  font-weight: 700;
`;


const GraphDiv = styled.div`
  width: 100%;
  height:400px;
  position: relative;
`;


const MetricProjection = ({report, scoutAnalysis}) => {

  const objectMap = scoutAnalysis?.monthly_expected_revenue ? Object.values(scoutAnalysis?.monthly_expected_revenue).map((data) => {
    return {
      graphData: data,
    }
  }) : [];

  const xTickFormat = (data) => {
    return scoutAnalysis?.monthly_expected_revenue ?  HALF_MONTH_NAME[data] : '?';
  };

  const maxDomain = scoutAnalysis?.monthly_expected_revenue ? Math.max(Object.values(scoutAnalysis?.monthly_expected_revenue)) : '?';

  const yTickFormat = (data) => {
    return  scoutAnalysis?.monthly_expected_revenue ? `$${data.toFixed(0)}` : 0;
  }
  
  return (
    <MetricProjectionDiv $width={report ? "660px" : "894px"}>
      <BodyTitle>
        1 Year Projection
      </BodyTitle>
      <EstimatedDiv>
        <BodyText>
          Estimated by range
        </BodyText>
      </EstimatedDiv>
      <EstimatedDiv>
        <MetricDiv $width={report ? "192px" : "270px"}>
          <MetricValue $color="#1BB3CD">
            {scoutAnalysis?.total_revenue ?  `$${scoutAnalysis?.total_revenue}` : "$???"}
          </MetricValue>
          <BodyText>
            Revenue
          </BodyText>
        </MetricDiv>
        <MetricDiv $width={report ? "192px" : "270px"}>
          <MetricValue $color="#EDB548">
            {scoutAnalysis?.adr ? `$${scoutAnalysis?.adr}` : "$???"}
          </MetricValue>
          <BodyText>
            ADR
          </BodyText>
        </MetricDiv>
        <MetricDiv $width={report ? "192px" : "270px"}>
          <MetricValue $color="#9747FF">
          {scoutAnalysis?.occupancy_rate ? `${(scoutAnalysis?.occupancy_rate * 100).toFixed(0)}%` : "$???"}
          </MetricValue>
          <BodyText>
            Occupancy
          </BodyText>
        </MetricDiv>
      </EstimatedDiv>
      <BodyTitle>
        Monthly Projected Revenue
      </BodyTitle>
      <GraphDiv>
        <ResponsiveContainer>
          <BarChart  
            data={objectMap}
          >
            <XAxis
              stroke="#D9D9D9"
              tickLine={false}  
              tick={{stroke: '#283334', fontWeight: 300, strokeWidth: 0.5}}
              tickFormatter={xTickFormat}
            />
            <YAxis  
              domain={[0, maxDomain]} 
              stroke="#D9D9D9"
              margin={{left: 10}}
              tickLine={false}  
              tick={{stroke: '#283334', fontWeight: 300, strokeWidth: 0.5}}
              padding={{top: 10}}
              tickFormatter={yTickFormat}
            />
            <Bar dataKey="graphData" barSize={24} fill="#00BBD2" />
          </BarChart>
        </ResponsiveContainer>
      </GraphDiv>
  </MetricProjectionDiv>
  )
}

export default MetricProjection;
import React from 'react';

import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import markerIcon from 'assets/images/generic/marker.png';



const MarkerDiv = styled.div`
  width: fit-content;
  height: fit-content;
  margin: -60px 0 0 -30px;
`;

const ScoutMap = ({lat, lon,}) => {
  return (
    <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBfvIGHRXKZpovQBnn8tmKuIEJzOjZFjKM" }}
        defaultCenter={{  lat: 40.7128, lng: -74.0060}}
        center={{  lat: lat, lng: lon}}
        defaultZoom={13}
        yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
        options={
          {
            mapTypeId:"terrain",
            clickableIcons: false,
            fullscreenControl: false,
            scrollwheel: false,
            styles:[
              {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                  { "visibility": "off" }
                ]
              },
            ]
        }}
      >
        <MarkerDiv lat={lat} lng={lon}>
          <img width={60} height={76} src={markerIcon} alt="marker icon"/>
        </MarkerDiv>
  </GoogleMapReact>
  )
}

export default ScoutMap;


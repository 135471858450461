import React from 'react';
import styled, { keyframes } from 'styled-components';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';
import { showName } from '../../../../util/helper';


const CompetitorListDiv = styled.div`
  width: ${({$width}) => $width};
  max-height: 880px;
  border-top: 1px solid #D9D9D9;
`;

const ListBody = styled.div`
  width: 100%:
  height: ${({height}) => height};
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
`;

const ListItem = styled.div`
  width: 300px;
  height: 100%; 
  display: flex;
  gap: 2px;
  flex-direction: column;
  justify-content: center;
`;

// const ListItemName = styled(ListItem)`
//   width: 300px;
// `;

const PercentShare = styled.div`
  display: flex;
  gap: 4px;
  color: #9747FF;
`;

const ImageDiv = styled.div`
  width: 120px;
  height: ${({$height}) => $height};
  border-radius: 4px;
  background: #697273;
  margin: 0 16px 0 0;
  position: relative;
  object-fit: cover;
`;

const CompImg = styled.img`
  width: 120px;
  height: 100%; 
  object-fit: cover;
`;

const ItemText = styled(BodyText)`
  margin: 0px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 6px solid #00BBD2; 
  border-top: 6px solid  white; 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
`;

const ListExtended = styled.div`
  width: 250px;
`;



const CompetitorList = ({report, competitorList}) => {

  const competitors = competitorList?.length ? competitorList : [...Array(10).keys()];

  return (
    <CompetitorListDiv $width={report ? "1030px" : "1438px"}>
       {competitors.map ((data, index) => (
        <ListBody $height={report ? "70px" : "88px"} key={index}>
            <ListItem>
              <ItemText>
                {showName(data?.name, report ? 24 : 34)  || 'Competitor'}
              </ItemText>
              <PercentShare>
                {data?.comp_score !== undefined ? `${(data?.comp_score * 100).toFixed(0)}%` : '?% share'}
              </PercentShare>
            </ListItem>
            <ImageDiv $height={report ? "40px" : "60px"}>
              {data?.image_url ? <CompImg  src={data?.image_url } /> : null}
              {report || data?.image_url  ? null : <Loader /> }
            </ImageDiv>
            <ListExtended>
              <ItemText>
                {data?.price_per_night ? `$${data?.price_per_night.toFixed(0)}` : '$?'} Night
              </ItemText>
              <ItemText>
                Min Stay: {data?.min_nights?.toFixed(0) || "?" }              
              </ItemText>
            </ListExtended>
            <ListExtended>
              <ItemText>
                Bedrooms: {data?.bedrooms || "'?'"}
              </ItemText>
              <ItemText>
                Scenic View: {data?.scenic_view || "?" }  
              </ItemText>
            </ListExtended>
            <ListExtended>
              <ItemText>
                Rating: {data?.rating || '?'}
              </ItemText>
              <ItemText>
                Reviews: {data?.reviews || '?'}
              </ItemText>
            </ListExtended>
            <ListExtended>
              <ItemText>
                Jacuzzi: {data?.jacuzzi || '?'}
              </ItemText>
              <ItemText>
                Pool: {data?.pool || '?'}
              </ItemText>
            </ListExtended>
          </ListBody>
       )) }
        </CompetitorListDiv>
  )
}

export default CompetitorList;
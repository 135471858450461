import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { Input } from 'antd';

import { message } from "antd/lib/index";

import { useSelector } from 'react-redux';

import { OptimizeBody } from '../../CommonCSS';
import { BodyTitle } from 'components/ReusableComponents/Text/Text';

import IncrementDecrementInput from './IncrementDecrementInput';
import ExpandableText from './ExpandableText';
import { PriceAdjustContext } from '../../../../PriceAdjustment';
import QuibbleMenu from '../../../../../../components/ReusableComponents/Menu/Menu';

const StayTitle = styled(BodyTitle)`
  align-self: flex-start;
  font-size: 18px;
  margin: 12px 0;
  padding: 8px 0;
  border-bottom: 1px solid #D9D9D9;
  width: 100%;
`;

const TabTitle = styled(BodyTitle)`
  align-self: flex-start;
  margin: 12px 0;
`;

const SettingInEffectText = styled.div`
  width: 100%;
  margin-bottom: 12px;
  font-size: 16px;
`;

const ExpandableTextContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
`;

const BookingWindowItemContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const BookingWindowText = styled.div`
  flex: 1;
  padding: 5px 0;
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 5px;
`;

const MinimumStayContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DayContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DayInputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 5px;
`;

const DayTextContainer = styled.div`
  width: 40px;
`;

const MinimumStayText = styled.div`
  flex: 1;
  padding: 0 5px;
`;

const OrphanGapsContainer = styled.div`
  width: 100%;
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  background-color: #E8E8E8;
`;

const OrphanGapsText = styled.div`
  display: flex;
  width: 40%;
  color: #283334;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
`;

const OrphanGapsInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const GapSizeBetweenInput = styled.div`
  flex: 1;
  display: flex;
`;

const MinStayInput = styled.div`
  flex: 1;
  display: flex;
`;

const HighLowText = styled.div`
  flex: 1;
  padding: 0 5px;
`;

const BookingWindow = ({ isDisabled, closeInValue, farOutValue, standardRangeValue, onCloseInValueChange, onFarOutValueChange }) => {

  return (
    <>
      <StayTitle>Booking Window</StayTitle>
      <BookingWindowItemContainer>
        <BookingWindowText>
          Close in Start Day
        </BookingWindowText>
        <InputContainer>
          <IncrementDecrementInput disabled={isDisabled} inputValue={closeInValue} onInputChange={onCloseInValueChange} />
        </InputContainer>
      </BookingWindowItemContainer>
      <BookingWindowItemContainer>
        <BookingWindowText>
          Standard Range
        </BookingWindowText>
        <InputContainer>
          <Input
            style={{ width: '100%', textAlign: 'center', backgroundColor: 'white', color: '#283334', fontWeight: 500 }}
            value={standardRangeValue}
            disabled
          />
        </InputContainer>
      </BookingWindowItemContainer>
      <BookingWindowItemContainer>
        <BookingWindowText>
          Far Out start Day
        </BookingWindowText>
        <InputContainer>
          <IncrementDecrementInput disabled={isDisabled} inputValue={farOutValue} onInputChange={onFarOutValueChange} />
        </InputContainer>
      </BookingWindowItemContainer>
    </>
  );
};

const MinimumStay = ({ isDisabled, minStayArr, onMinStayInputChange }) => {

  const getDayString = (numericDay) => {
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return daysOfWeek[numericDay -1];
  };

  return (
    <>
      <StayTitle>Minimum Stay</StayTitle>
      <MinimumStayContainer>
        <DayContainer >
          <DayTextContainer></DayTextContainer>
          <DayInputContainer>
            <MinimumStayText>Close In</MinimumStayText>
            <MinimumStayText>Standard</MinimumStayText>
            <MinimumStayText>Far out</MinimumStayText>
          </DayInputContainer>
        </DayContainer>
        {Object.keys(minStayArr).map((numericDay, index) => {
          const dayString = getDayString(parseInt(numericDay, 10));
          return (
            <DayContainer key={`$day-${dayString}`}>
              <DayTextContainer>{dayString}</DayTextContainer>
              <DayInputContainer>
                <InputContainer>
                  <IncrementDecrementInput
                    disabled={isDisabled}
                    inputValue={minStayArr[numericDay].closeIn || 0}
                    onInputChange={(newValue) => onMinStayInputChange(newValue, numericDay, 'closeIn')}
                  />
                </InputContainer>
                <InputContainer>
                  <IncrementDecrementInput
                    disabled={isDisabled}
                    inputValue={minStayArr[numericDay].standard || 0}
                    onInputChange={(newValue) => onMinStayInputChange(newValue, numericDay, 'standard')}
                  />
                </InputContainer>
                <InputContainer>
                  <IncrementDecrementInput
                    disabled={isDisabled}
                    inputValue={minStayArr[numericDay].farOut || 0}
                    onInputChange={(newValue) => onMinStayInputChange(newValue, numericDay, 'farOut')}
                  />
                </InputContainer>
              </DayInputContainer>
            </DayContainer>
          );
        })}
      </MinimumStayContainer>
    </>
  );
};

const OrphanGaps = ({
  isDisabled,
  gapSizeBetweenHigh,
  gapSizeBetweenLow,
  minStayForOrphanGaps,
  onGapSizeBetweenHighChange,
  onGapSizeBetweenLowChange,
  onMinStayForOrphanGapsChange,
}) => {
  return (
    <>
      <StayTitle>Orphan Gaps</StayTitle>
      <OrphanGapsContainer >
        <OrphanGapsInputContainer>
          <OrphanGapsText>
          </OrphanGapsText>
          <GapSizeBetweenInput>
            <HighLowText>High</HighLowText>
            <HighLowText>Low</HighLowText>
          </GapSizeBetweenInput>
        </OrphanGapsInputContainer>

        <OrphanGapsInputContainer>
          <OrphanGapsText>
            Gap Size Between
          </OrphanGapsText>
          <GapSizeBetweenInput>
            <InputContainer>
              <IncrementDecrementInput
                disabled={isDisabled}
                inputValue={gapSizeBetweenHigh}
                onInputChange={onGapSizeBetweenHighChange}
              />
            </InputContainer>
            <InputContainer>
              <IncrementDecrementInput
                disabled={isDisabled}
                inputValue={gapSizeBetweenLow}
                onInputChange={onGapSizeBetweenLowChange}
              />
            </InputContainer>
          </GapSizeBetweenInput>
        </OrphanGapsInputContainer>

        <OrphanGapsInputContainer>
          <OrphanGapsText>
            Min Stay
          </OrphanGapsText>
          <MinStayInput>
            <InputContainer>
              <IncrementDecrementInput
                disabled={isDisabled}
                inputValue={minStayForOrphanGaps}
                onInputChange={onMinStayForOrphanGapsChange}
              />
            </InputContainer>
          </MinStayInput>
        </OrphanGapsInputContainer>
      </OrphanGapsContainer>
    </>
  );
};

const menuItems = [
  { label: 'Portfolio', key: 'portfolio' },
  { label: 'Market', key: 'market' },
  { label: 'Property', key: 'property' },
];

const Stay = ({ adjustmentMenu }) => {

  const { setStayRules, activeMarket, activeProperty } = useContext(PriceAdjustContext);

  const [pricingRules, setPricingRules] = useState({
    portfolio: {},
    market: {},
    property: {},
  });


  // Display pricing rules based on menuValue
  const displayPricingRules = (menu) => {
    switch (menu) {
      case 'market':
        return pricingRules.market;
      case 'property':
        return pricingRules.property;
      case 'portfolio':
        return pricingRules.portfolio;
      default:
        return {};
    }
  };

  const [staysData, setStaysData] = useState(pricingRules?.protfolio || {});

  const [closeInValue, setCloseInValue] = useState(0);
  const [farOutValue, setFarOutValue] = useState(0);
  const [standardRangeValue, setStandardRangeValue] = useState('-');
  const [minStayArr, setMinStayArr] = useState({
    "1": { closeIn: 0, standard: 0, farOut: 0 },
    "2": { closeIn: 0, standard: 0, farOut: 0 },
    "3": { closeIn: 0, standard: 0, farOut: 0 },
    "4": { closeIn: 0, standard: 0, farOut: 0 },
    "5": { closeIn: 0, standard: 0, farOut: 0 },
    "6": { closeIn: 0, standard: 0, farOut: 0 },
    "7": { closeIn: 0, standard: 0, farOut: 0 },
  });

  const [gapSizeBetweenHigh, setGapSizeBetweenHigh] = useState(0);
  const [gapSizeBetweenLow, setGapSizeBetweenLow] = useState(0);
  const [minStayForOrphanGaps, setMinStayForOrphanGaps] = useState(0);

  const [menuValue, setMenuValue] = useState('portfolio');
  
  const [inputDisabled, setInputDisabled] = useState(false);

  const { marketPricingRules, isFetchingMarketPricingRules, portfolioPricingRules, isFetchingPortfolioPricingRules } = useSelector(({ markets }) => markets);
  const { propertyPricingRules, isFetchingPropertyPricingRules } = useSelector(({ property }) => property);

  const resetValues = () => {
    setCloseInValue(0);
    setFarOutValue(0);
    setStandardRangeValue('-');
    setMinStayArr({
      "1": { closeIn: 0, standard: 0, farOut: 0 },
      "2": { closeIn: 0, standard: 0, farOut: 0 },
      "3": { closeIn: 0, standard: 0, farOut: 0 },
      "4": { closeIn: 0, standard: 0, farOut: 0 },
      "5": { closeIn: 0, standard: 0, farOut: 0 },
      "6": { closeIn: 0, standard: 0, farOut: 0 },
      "7": { closeIn: 0, standard: 0, farOut: 0 },
    });
    setGapSizeBetweenHigh(0);
    setGapSizeBetweenLow(0);
    setMinStayForOrphanGaps(0);
  };

  const handleCloseInValueChange = (newValue) => {
    let newFarOutValue;
    if (newValue > 0 ) {
      if (farOutValue === 0) {
        newFarOutValue = newValue + 2;

        handleInputChange('farOutStartDay', newFarOutValue);
        setFarOutValue(newFarOutValue);
      }

      if (farOutValue > 0 && newValue > farOutValue - 2) {
        newFarOutValue = newValue + 2;

        handleInputChange('farOutStartDay', newFarOutValue);
        setFarOutValue(newFarOutValue);
      }
    }

    handleInputChange('closeInStartDay', newValue);
    setCloseInValue(newValue);
  };

  const handleFarOutValueChange = (newValue) => {
    let newFarOutValue = newValue;
    if (closeInValue !== 0) {
      if (newValue < closeInValue + 2) {
        message.error('Far out value cannot be less than close in value.')
        newFarOutValue = closeInValue + 2;
      }
    }

    handleInputChange('farOutStartDay', newValue);
    setFarOutValue(newFarOutValue);
  };

  const handleMinStayInputChange = (newValue, day, field) => {
    handleInputChange(field, newValue, true, day);
    setMinStayArr((prevArr) => ({
      ...prevArr,
      [day]: {
        ...prevArr[day],
        [field]: newValue,
      },
    }));
  };


  const handleGapSizeBetweenHighChange = (newValue) => {
    handleInputChange('highGap', newValue);
    setGapSizeBetweenHigh(newValue);
  };

  const handleGapSizeBetweenLowChange = (newValue) => {
    handleInputChange('lowGap', newValue);
    setGapSizeBetweenLow(newValue);
  };

  const handleMinStayForOrphanGapsChange = (newValue) => {
    handleInputChange('minStay', newValue)
    setMinStayForOrphanGaps(newValue);
  };

  useEffect(() => {
    if (farOutValue - closeInValue === 2) {
      setStandardRangeValue((farOutValue + closeInValue) / 2);
    }
    else if (farOutValue > closeInValue + 2) {
        const rangeStart = closeInValue + 1;
        const rangeEnd = farOutValue - 1;
        const newStandardRangeValue = `${rangeStart} - ${rangeEnd}`;
        setStandardRangeValue(newStandardRangeValue);
    } else {
      setStandardRangeValue('-');
    }
  }, [closeInValue, farOutValue]);

  useEffect(() => {
    setPricingRules((prevRules) => ({
      ...prevRules,
      market: marketPricingRules.stays,
    }));
  }, [marketPricingRules, isFetchingMarketPricingRules]);

  useEffect(() => {
    setPricingRules((prevRules) => ({
      ...prevRules,
      property: propertyPricingRules.stays,
    }));
  }, [propertyPricingRules, isFetchingPropertyPricingRules]);

  useEffect(() => {
    setStaysData(portfolioPricingRules.stays);
    setPricingRules((prevRules) => ({
      ...prevRules,
      portfolio: portfolioPricingRules.stays,
    }));
  }, [portfolioPricingRules, isFetchingPortfolioPricingRules]); 

  useEffect(() => {
    if (staysData) {
      setCloseInValue(staysData?.closeInStartDay || 0);
      setFarOutValue(staysData?.farOutStartDay || 0);
      setMinStayArr(staysData?.day || {
        "1": { closeIn: 0, standard: 0, farOut: 0 },
        "2": { closeIn: 0, standard: 0, farOut: 0 },
        "3": { closeIn: 0, standard: 0, farOut: 0 },
        "4": { closeIn: 0, standard: 0, farOut: 0 },
        "5": { closeIn: 0, standard: 0, farOut: 0 },
        "6": { closeIn: 0, standard: 0, farOut: 0 },
        "7": { closeIn: 0, standard: 0, farOut: 0 },
      });
      setGapSizeBetweenHigh(staysData?.orphan?.highGap || 0);
      setGapSizeBetweenLow(staysData?.orphan?.lowGap || 0);
      setMinStayForOrphanGaps(staysData?.orphan?.minStay || 0);
    } else {
      resetValues();
    }
  }, [staysData, adjustmentMenu]);

  useEffect(() => {
    if (adjustmentMenu === menuValue) {
      setInputDisabled(false);
    } else {
      setInputDisabled(true);
    }
  }, [menuValue, adjustmentMenu]);

  const handleInputChange = (field, value, isDayField = false, day = null) => {
    setStayRules((prevRules) => {
      const updatedRules = { ...prevRules };

      const key = adjustmentMenu === 'market' ? activeMarket : adjustmentMenu === 'property' ? activeProperty : adjustmentMenu;

      const updatedKey = adjustmentMenu === 'portfolio' ? adjustmentMenu : key;

      updatedRules[adjustmentMenu] = updatedRules[adjustmentMenu] || {};
      updatedRules[adjustmentMenu][updatedKey] = {
        ...updatedRules[adjustmentMenu]?.[updatedKey],
        stays: {
          ...updatedRules[adjustmentMenu]?.[updatedKey]?.stays,
          ...(isDayField
            ? {
                day: {
                  ...updatedRules[adjustmentMenu]?.[updatedKey]?.stays?.day,
                  [day]: {
                    ...((updatedRules[adjustmentMenu]?.[updatedKey]?.stays?.day &&
                      updatedRules[adjustmentMenu]?.[updatedKey]?.stays?.day[day]) ||
                      {}),
                    [field]: value,
                  },
                },
              }
            : {
                [field]: value,
              }),
          ...(field === 'highGap' || field === 'lowGap' || field === 'minStay'
            ? {
                orphan: {
                  ...updatedRules[adjustmentMenu]?.[updatedKey]?.stays?.orphan,
                  [field]: value,
                },
              }
            : {}),
        },
      };

      if (field === 'highGap' || field === 'lowGap' || field === 'minStay') {
        delete updatedRules[adjustmentMenu][updatedKey]?.stays[field];
      }

      return updatedRules;
    });
  };

  const handleMenuChange = (value) => {
    const data = displayPricingRules(value);
    setStaysData(data);
    setMenuValue(value);
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const settingsInEffectTextCases = () => {
    const menuText = capitalizeFirstLetter(menuValue);
    const notInEffect = `${menuText} Stay settings not in effect`;
    const inEffect = `${menuText} Stay settings in effect`;

    let inEffectCondition = false;

    const currentAdjustmentMenu = adjustmentMenu;

    switch (currentAdjustmentMenu) {
        case 'portfolio':
            inEffectCondition = menuValue === 'portfolio';
            break;
        case 'market':
            if (menuValue === 'market') {
                inEffectCondition = pricingRules.market !== null && pricingRules.market !== undefined && Object.keys(pricingRules.market).length > 0;
            } else if (menuValue === 'portfolio') {
                inEffectCondition = pricingRules.market === null || (pricingRules.market && Object.keys(pricingRules.market).length === 0);
            }
            break;
        case 'property':
            if (menuValue === 'property') {
                inEffectCondition = pricingRules.property !== null && pricingRules.property !== undefined && Object.keys(pricingRules.property).length > 0;
            } else if (menuValue === 'market') {
                inEffectCondition = pricingRules.property === null && pricingRules.market !== null && pricingRules.market !== undefined && Object.keys(pricingRules.market).length > 0;
            } else if (menuValue === 'portfolio') {
                inEffectCondition = pricingRules.property === null && pricingRules.market === null;
            }
            break;
        default:
            break;
    }

    return inEffectCondition ? inEffect : notInEffect;
  };


  return (
    <OptimizeBody>
      {/* Display pricing rules based on adjustmentMenu */}
      <TabTitle>Stay Settings</TabTitle>
      <QuibbleMenu menuItems={menuItems} onMenuChange={handleMenuChange} menuAlignment="center" menuWidth="33.33%" margin="12px 0" />
      <SettingInEffectText  style={{ color: inputDisabled ? '#697273' : '#4C9714'  }} >{settingsInEffectTextCases()}</SettingInEffectText>
      <BookingWindow
        isDisabled={inputDisabled}
        closeInValue={closeInValue}
        farOutValue={farOutValue}
        standardRangeValue={standardRangeValue}
        onCloseInValueChange={handleCloseInValueChange}
        onFarOutValueChange={handleFarOutValueChange}
      />
      <ExpandableTextContainer>
        <ExpandableText/>
      </ExpandableTextContainer>
      <MinimumStay  isDisabled={inputDisabled} minStayArr={minStayArr} onMinStayInputChange={handleMinStayInputChange} />
      <OrphanGaps
        isDisabled={inputDisabled}
        gapSizeBetweenHigh={gapSizeBetweenHigh}
        gapSizeBetweenLow={gapSizeBetweenLow}
        minStayForOrphanGaps={minStayForOrphanGaps}
        onGapSizeBetweenHighChange={handleGapSizeBetweenHighChange}
        onGapSizeBetweenLowChange={handleGapSizeBetweenLowChange}
        onMinStayForOrphanGapsChange={handleMinStayForOrphanGapsChange}
      />
    </OptimizeBody>
  )
}

export default Stay;

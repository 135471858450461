/** Default Import */
import React, { createContext, useState } from "react";
import { Route, Switch } from "react-router-dom";

/** Custom Import */
import errorsPages from "../../errorsPages/index";
import PropertySearch from "./Property-Search/PropertySearch";
import ScoutProperties from "./Scout-Properties/ScoutProperties";
import Report from "./Reports/Report";
import ScoutAnalysis from "./Scount-Analysis/ScoutAnalysis";


export const PropertyScoutContext = createContext("");


const PropertyScout = ({match}) => {
  const [analysing, setAnalysing] = useState(false);
  const [scoutAnalysis, setScoutAnalysis] = useState({})
  const [analysisForm, setAnalysisForm] = useState({
    price_per_night: '',
    bedrooms: 0,
    bathrooms: 0,
    beds: 0,
    jacuzzi: false,
    pool: false,
    scenic_view: false,
    images: undefined,
    image_score: '',
  });
  
  return (
    <PropertyScoutContext.Provider value={{analysing, setAnalysing, analysisForm, setAnalysisForm, scoutAnalysis, setScoutAnalysis}}>
      <Switch>
        <Route
          path={`${match.url}/properties`}
          component={ScoutProperties}
        />
        <Route
          path={`${match.url}/reports`}
          component={Report}
        />
        <Route
          path={`${match.url}/analysis`}
          component={ScoutAnalysis}
        />
        <Route
          path={`${match.url}`}
          component={PropertySearch}
        />
        <Route exact component={errorsPages} />
      </Switch>
    </PropertyScoutContext.Provider>
  );
} 
export default PropertyScout;

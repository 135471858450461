import React from 'react';
import styled from 'styled-components';
import { BodyTitle } from '../../../../components/ReusableComponents/Text/Text';
import AdjustMetrics from './AdjustMetrics';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MetricProjection from './MetricProjection';
import CompetitorList from './CompetitorList';
import { useContext } from 'react';
import { PropertyScoutContext } from '..';
import ScoutMap from './ScoutMap';




const AnalysisResultsDiv = styled.div`
  max-width: 1812px;
  min-width: 1812px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Header = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeaderButtonsDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const HeaderButton = styled.button`
  width: 155px;
  height: 40px;
  background: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid #1BB3CD;
  color: #1BB3CD;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.25s linear;

  &:hover {
    color: white;
    border: white;
    background: #1BB3CD;
  }
`;

const DownloadButton = styled(HeaderButton)`
  background: #00A4E6;
  color: white;
`;


const AdjustListBody = styled(Header)`
  align-items: flex-start;
`;


const MapsDiv = styled.div`
  width: 894px;
  height: 792px;
  background: #1BB3CD;
  border-radius: 8px;
`;







const AnalysisResults = ({onPrint}) => {

  const history = useHistory();
  
  const {scoutAnalysis, analysisForm, setAnalysisForm} = useContext(PropertyScoutContext);
  const {lat, lon,} = scoutAnalysis;

  const isResult = scoutAnalysis?.intel_comp_options?.length; 


  const onNewSearchClick = () => {
    history.push("/businessTools/property-scout");
  };

  const onProperties = () => {
    history.push("/businessTools/property-scout/properties");
  }

  return (
    <AnalysisResultsDiv>
      <Header>
        <BodyTitle>
          {isResult ? scoutAnalysis?.display_name :"Hang tight! Our system is comparing your property to others in the area. Check the scout properties list later." }
        </BodyTitle>
        <HeaderButtonsDiv>
          <HeaderButton onClick={onNewSearchClick}>
            New Search
          </HeaderButton>
          {isResult ? 
          <>
          <HeaderButton onClick={onProperties}>
            Properties
          </HeaderButton>
          <HeaderButton>
            Delete
          </HeaderButton>
          <DownloadButton onClick={onPrint}>
            Download
          </DownloadButton>
          </>
          :
          <HeaderButton onClick={onProperties}>
            Properties
          </HeaderButton>}
        </HeaderButtonsDiv>
      </Header>
      <AdjustListBody>
        <AdjustMetrics  analysisForm={analysisForm} setAnalysisForm={setAnalysisForm} report={true}/>
        <CompetitorList competitorList={scoutAnalysis?.intel_comp_options}/>
      </AdjustListBody>
      <AdjustListBody>
        <MetricProjection scoutAnalysis={scoutAnalysis} />
        <MapsDiv>
         <ScoutMap lat={lat} lon={lon} />
        </MapsDiv>
      </AdjustListBody>
    </AnalysisResultsDiv>
  )
}

export default AnalysisResults;


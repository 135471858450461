import styled, { css } from "styled-components";
import { BodyCSS } from "../../../../components/ReusableComponents/Card-Body/BodyCSS";
import { BodyText } from "../../../../components/ReusableComponents/Text/Text";
import { message } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { showName } from "../../../../util/helper";
import ButtonWithFunction from "../../../../components/ReusableComponents/Button/ButtonWithFunction";
import { useCallback, useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { property } from "../../../../services/property";
import { PropertyScoutContext } from "..";

const PropertySetDiv = styled.div`
  ${BodyCSS}
  width: 360px;
  height: ${({$height}) => $height};
  margin: 0 0 0 0;
  border: 1px solid #D9D9D9;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
`;

const ImageDiv = styled.div`
  width: 300px;
  height: 150px;
  border: 1px solid #D9D9D9;
  position: relative;
  background: #D9D9D9;
`;

const ItemDiv = styled.div`
  width: 98%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const ItemBetweenDiv = styled(ItemDiv)`
  justify-content: space-between;
`;


const AnalyseButton = styled.button`
  width: 155px;
  height: 40px;
  background: #1BB3CD;
  color: white;
  outline: none;
  border: none;
  margin: 24px 0 0 0;
  cursor: pointer;

  &:disabled {
    background: grey;
  }
`;

const UploadButton = styled.input`
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: none;
`;

const UploadLabel = styled.label`
  width: fit-content;
  height: 28px;
  position: absolute;
  bottom: 2px;
  left: 2px;
  margin: none;
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
  padding: 4px 8px;
`;

const RemoveButton = styled.button`
  width: fit-content;
  height: 28px;
  position: absolute;
  top: 2px;
  right: 2px;
  margin: none;
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
  padding: 4px 8px;
  border: none;
  outline: none;
`;

const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  background-size: cover;
  font-size: 0px;
  border-radius: 4px;
`;

const ImageScoreDiv = styled.div`
  width: 104px;
  height: 78px;
  background: white; 
  border-radius: 4px;
  position: absolute;
  top: 2px;
  left: 2px;
  display: flex;
  flex-direction: column;
  padding: 2px 4px;
`;

const ImageScoreText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

const ImageScoreLabel = styled.h1`
  color: #283334;
  font-family: Commissioner;
  font-size: 14px;
  margin: 0px;
  font-weight: 300;
`;

const ImageScoreInput = styled.input`
  width: 100%;
  font-size: 24px;
  height: 48px;
  margin: 2px;
  border-radius: 4px;
  font-family: Commissioner;
  font-weight: 300;
  border: 1px solid #CDCDCD;
  outline: none;
`;

const PricePerNight = styled.input`
  width: 80px;
  font-size: 16px;
  margin: 0 0 0 8px;
  outline: none;
  font-weight: 400;
  border: 1px solid #CDCDCD;
  border-radius: 2px;
`;

const RangeInput = styled.input`
  -webkit-appearance: none;  
  appearance: none;
  width: 100%; 
  height: 3px; 
  background: #D9D9D9;
  outline: none;
  opacity: 1;
  cursor: pointer;

  ::-webkit-slider-thumb {
      -webkit-appearance: none; 
      appearance: none;
      width: 16px; 
      height: 16px;
      border-radius: 100%;
      border: 2px solid #00A4E6;
      outline: none;
      background: white; /* Green background */
      cursor: pointer; /* Cursor on hover */
      opacity: 1;

      &:hover {
        background: #00A4E6; 
      }
  }
`;

const ButtonsDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
`;

const ButtonCss = css`
  width: 42px;
  height: 22px;
  border: 1px solid #00A4E6;
  outline: none;
  background: white;
  color: #00A4E6;
  text-align: center;
  font-size: 14px;
  border-radius: ${({$radius}) => $radius || 'none'};
  box-shadow: none;

  &:hover, &:focus, &.active {
    background: #00A4E6;
    color: white;
  };
`;


const AdjustMetrics = ({report}) => {

  const history = useHistory();
  const {scoutAnalysis, analysisForm, setAnalysisForm, setAnalysing} = useContext(PropertyScoutContext);
  
  useEffect(() => {
    if(!scoutAnalysis?.display_name && !report) {
      history.push("/businessTools/property-scout");
    } 
  },[scoutAnalysis, history, report])

  const {lat, lon,} = scoutAnalysis;

  const onAnalyse = useCallback(async (e) => {
    e.preventDefault();
    try {

      if ((!analysisForm.image_score || analysisForm.image_score <= 0) && !analysisForm.images) {
        message.error("Please add image score or upload an image", 3);
        return;
      };

      if (!analysisForm.price_per_night) {
        message.error("Please add price per night", 3);
        return;
      };
  
      const params = {
        latitude:lat,
        longitude : lon,
        price_per_night: Number(analysisForm.price_per_night),
        bedrooms: Number(analysisForm.bedrooms),
        bathrooms: Number(analysisForm.bathrooms),
        beds: Number(analysisForm.beds), 
        jacuzzi: analysisForm.jacuzzi, 
        pool: analysisForm.pool,
        scenic_view: analysisForm.scenic_view,
        images: analysisForm.images || [],
        image_score: parseFloat(analysisForm.image_score),
      };

      const formData = new FormData();
      
      Object.keys(params).forEach((objectKey) => {
        if (params[objectKey] !== undefined ) {
          formData.append(objectKey, params[objectKey]);
        }
      });
      
      const result = await property.propertyScoutAnalyse(formData);

      if (result.status === 'success') {
        setAnalysing(true);  
        return;
      }

      message.error("failed to submit settings, please try again", 3)
    } catch (e) {
      console.error(e);
      message.error("failed to submit settings, please try again", 3)
    }
    
  
  }, [analysisForm, setAnalysing, lat, lon]); 

  const onUploadChange = (e) => {
    if (report) return;
    if (e?.target?.files.length) {   
      if (e?.target?.files[0].size > 1000000) {
        message.error("File too large", 3)
        return;
      };

      setAnalysisForm((prev) => ({...prev, images: e?.target?.files[0] }) )
    }
    return;
  };

  const onRemoveUpload = (e) => {
    setAnalysisForm((prev) => ({...prev, images: undefined }) )
    return;
  };

  const onImageScoreChange = (e) => {
    if (report) return;
    e.preventDefault();
    if (e?.target?.value) {
      const value = e?.target?.value;

      if (parseFloat(value) > 10) {
        const imageScore = document.getElementById("imageScore");
        imageScore.value = 10;
        setAnalysisForm((prev) => ({...prev, image_score: 10 }));
        return;
      };

      if (parseFloat(value) < 0) {
        const imageScore = document.getElementById("imageScore");
        imageScore.value = 0;
        setAnalysisForm((prev) => ({...prev, image_score: 0 }));
        return;
      };

      setAnalysisForm((prev) => ({...prev, image_score: e?.target?.value }));

    }
  };

  const onSliderchange = (e) => {
    if (report) return;
    e.preventDefault();
    const id = e.target?.id;
    const value = e.target?.value;

    setAnalysisForm((prev) => ({...prev, [id]: value }));

  };

  const onTrueClick = useCallback((val) => {
    if (report) return;
    setAnalysisForm((prev) => ({...prev, [val] : true}));
  }, [setAnalysisForm, report]);

  const onFalseClick = useCallback((val) => {
    if (report) return;
    setAnalysisForm((prev) => ({...prev, [val] : false}));
  }, [setAnalysisForm, report]);
  

  return (
    <form onSubmit={onAnalyse}>
      <PropertySetDiv $height={report ? "640px" : "720px"} >
        <BodyText>
          {showName(scoutAnalysis?.display_name || 'N/A', 38)}
        </BodyText>
        <ImageDiv>
          {Number(analysisForm.image_score) > 0 || report ? null : <UploadLabel htmlFor="uploadedImage" >{analysisForm.images ? "Upload different photo" : "Upload photo"} </UploadLabel> }
          {analysisForm.images && !report ? <RemoveButton onClick={onRemoveUpload}>Remove Upload</RemoveButton> : null}
          <UploadButton accept="image/*"  type="file" id="uploadedImage" name="filename" onChange={onUploadChange}/>
          <UploadImage id="imagePreview" src={analysisForm.images ? typeof analysisForm.images === 'string' ? analysisForm.images : URL.createObjectURL(analysisForm.images) : ''} alt="upload preview" />
          {analysisForm.images ? null :
          <ImageScoreDiv>
            <ImageScoreText>
              <ImageScoreLabel>
                Image Score
              </ImageScoreLabel>
              <QuestionCircleOutlined />
            </ImageScoreText>
            <ImageScoreInput onChange={onImageScoreChange} value={analysisForm.image_score} id="imageScore" min={0} max="10" type="number" step=".1" />
          </ImageScoreDiv>}
        </ImageDiv>
        <ItemDiv>
          <BodyText>
            Price Per Night ($): 
          </BodyText>
          <PricePerNight value={analysisForm.price_per_night}  id="price_per_night"  type="number" onChange={onSliderchange}/>
        </ItemDiv>
        <ItemDiv>
          <BodyText id="bedroomText">
            Bedrooms: {analysisForm.bedrooms}
          </BodyText>
          <RangeInput value={analysisForm.bedrooms} type="range" min="0" max="10" id="bedrooms" onChange={onSliderchange} />
        </ItemDiv>
        <ItemDiv>
          <BodyText id="bathroomText">
            Bathrooms: {analysisForm.bathrooms}
          </BodyText>
          <RangeInput value={analysisForm.bathrooms} type="range" min="0" max="10" id="bathrooms" onChange={onSliderchange} />
        </ItemDiv>
        <ItemDiv>
          <BodyText id="bedsText">
            Beds: {analysisForm.beds}
          </BodyText>
          <RangeInput value={analysisForm.beds}  type="range" min="0" max="10" id="beds" onChange={onSliderchange} />
        </ItemDiv>
        <ItemBetweenDiv>
          <BodyText>
            Jacuzzi:
          </BodyText>
          <ButtonsDiv>
            <ButtonWithFunction type="button" className={analysisForm.jacuzzi && 'active'} ButtonStyle={ButtonCss} value={"jacuzzi"} onClickFunc={onTrueClick} $radius="4px 0 0 4px" label="YES"/>
            <ButtonWithFunction type="button" className={!analysisForm.jacuzzi && 'active'} ButtonStyle={ButtonCss} value={"jacuzzi"} onClickFunc={onFalseClick} $radius="0px 4px 4px 0px" label="NO" />
          </ButtonsDiv>
        </ItemBetweenDiv>
        <ItemBetweenDiv>
          <BodyText>
            Pool:
          </BodyText>
          <ButtonsDiv>
            <ButtonWithFunction type="button" className={analysisForm.pool && 'active'} ButtonStyle={ButtonCss} value={"pool"} onClickFunc={onTrueClick} $radius="4px 0 0 4px" label="YES"/>
            <ButtonWithFunction type="button" className={!analysisForm.pool && 'active'} ButtonStyle={ButtonCss} value={"pool"} onClickFunc={onFalseClick} $radius="0px 4px 4px 0px" label="NO" />
          </ButtonsDiv>
        </ItemBetweenDiv>
        <ItemBetweenDiv>
          <BodyText>
            Scenic View:
          </BodyText>
          <ButtonsDiv>
            <ButtonWithFunction type="button" className={analysisForm.scenic_view && 'active'} ButtonStyle={ButtonCss} value={"scenic_view"} onClickFunc={onTrueClick} $radius="4px 0 0 4px" label="YES"/>
            <ButtonWithFunction type="button" className={!analysisForm.scenic_view && 'active'} ButtonStyle={ButtonCss} value={"scenic_view"} onClickFunc={onFalseClick} $radius="0px 4px 4px 0px" label="NO" />
          </ButtonsDiv>
        </ItemBetweenDiv>
        { report ? null :
          <AnalyseButton disabled={report}>
            Analyze
          </AnalyseButton>}
      </PropertySetDiv>
    </form>
   
  )
}

export default AdjustMetrics;
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import QuibbleLogo from 'assets/images/generic/logo.svg';
import { BodyText, BodyTitle } from '../../../../components/ReusableComponents/Text/Text';
import AdjustMetrics from '../Scount-Analysis/AdjustMetrics';
import MetricProjection from '../Scount-Analysis/MetricProjection';
import CompetitorList from '../Scount-Analysis/CompetitorList';
import { useContext } from 'react';
import { PropertyScoutContext } from '..';
import ScoutMap from '../Scount-Analysis/ScoutMap';


const ReportPDFDiv = styled.div`
  width: 100%;
  max-width: 1288px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-self: center;

  @media screen {
    max-height: 0px;
    overflow: hidden;
	}

`;

const Page = styled.div`
  width: 1288px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0;
  background: white;
  border-radius: 2px;
  padding: 24px 132px 24px 80px;
  page-break-after:always;
`;

const LogoImg = styled.img`
  width: 150px;
  height: 68px;
  align-self: flex-start;
`;

const ReportLine = styled.div`
  margin: 16px 0 0 0;
  width: 1030px;
  height: 1px;
  border-top:  1px solid #697273;
`;

const ComparativeTitle = styled(BodyTitle)`
  font-size: 28px;
  margin: 16px 0 0 0;
  align-self: flex-start;
`;

const ComparativeText = styled(ComparativeTitle)`
  font-size: 16px;
  margin: 12px 0 0 0;
`;

const DisplayData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
  height: fit-content;
  flex-wrap: nowrap;
  gap: 16px;
`;

const MapsDiv = styled.div`
  width: 1030px;
  height: 480px;
  margin: 16px 0 0 0;
`;


const ProjectionDiv = styled.div`
  width: 1043px;
  height: 132px;
  margin: 32px 0 0 0;
  background: #F3F3F3;
  border-radius: 2px;
  padding: 12px 16px;
`;

const MetricDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 16px 0 0 0;
`;

const MetricItem = styled.div`
  width: 300px;
  height: 40px;
  border-radius: 2px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MetricText = styled.h1`
  margin: 0px;
  color: #283334;
  text-align: center;
  font-family: Commissioner;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
`;

const TableDiv = styled.div`
  width: max-content;
  height: auto;
  display: flex;
  flex-direction: row;
`;

const TableDivFirst = styled.div`
  width: 214px;
  height: inherit;
  padding: 4px 16px;
  border: 1px solid #697273;
  color: #000;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const TableDivSec = styled(TableDivFirst)`
  width: 820px;
  display: flex;
  gap: 24px;
`;

const FeatureDiv = styled.div`
    height: 100%;
    border-left: 1px solid #697273;
`;

const ReportPDF = forwardRef(({ ...props }, ref) => {
  
  const {scoutAnalysis, analysisForm} = useContext(PropertyScoutContext);
  

  const reportDate = new Date().toDateString().split(' ').slice(1).join(' ');
  const {lat, lon,} = scoutAnalysis;



  return (
    <ReportPDFDiv ref={ref}>
      <Page>
        <LogoImg src={QuibbleLogo} alt='quibblelogo'/>
        <ReportLine />
        <ComparativeTitle>
          Comparative Property Analysis & Revenue Report
        </ComparativeTitle>
        <ComparativeText>
          Report Date {reportDate}
        </ComparativeText>
        <DisplayData>
          <AdjustMetrics analysisForm={analysisForm} report={true}/>
          <MetricProjection report={true} scoutAnalysis={scoutAnalysis}/>
        </DisplayData>
        <MapsDiv>
          <ScoutMap lat={lat} lon={lon} />
        </MapsDiv>
      </Page>
      <Page>
        <LogoImg src={QuibbleLogo} alt='quibblelogo'/>
        <ReportLine />
        <ComparativeTitle>
          Comparative Property Analysis & Revenue Report
        </ComparativeTitle>
        <ComparativeText>
          Report Date {reportDate}
        </ComparativeText>
        <BodyTitle margin="24px 0 0 0">
          Executive Summary
        </BodyTitle>
        <BodyText margin="12px 0 0 0">
          This analysis compares your property against the top 10 comparable listings in the area, examining key metrics and potential revenue opportunities. The report provides detailed insights into market positioning and revenue optimization strategies.
        </BodyText>
        <ProjectionDiv>
          <BodyTitle>
            1 Year Projection
          </BodyTitle>
          <MetricDiv>
            <MetricItem>
              <MetricText>
                Revenue: {scoutAnalysis?.total_revenue ?  `$${scoutAnalysis?.total_revenue}` : "$???"}
              </MetricText>
            </MetricItem>
            <MetricItem>
              <MetricText>
                ADR: {scoutAnalysis?.adr ? `$${scoutAnalysis?.adr}` : "$???"}
              </MetricText>
            </MetricItem>
            <MetricItem>
              <MetricText>
                Occupancy: {scoutAnalysis?.occupancy_rate ? `${(scoutAnalysis?.occupancy_rate * 100).toFixed(0)}%` : "$???"}
              </MetricText>
            </MetricItem>
          </MetricDiv>
        </ProjectionDiv>
        <BodyTitle margin="24px 0 0 0">
            Property Overview
        </BodyTitle>
        <BodyText margin="12px 0 24px 0">
          Your Property
        </BodyText>
        <TableDiv>
          <TableDivFirst>
            Address
          </TableDivFirst>
          <TableDivSec>
            {scoutAnalysis?.display_name || '?'}
          </TableDivSec>
        </TableDiv>
        <TableDiv>
          <TableDivFirst>
            Price
          </TableDivFirst>
          <TableDivSec>
          {analysisForm.price_per_night ? `$${analysisForm.price_per_night}` : '?'} / night
          </TableDivSec>
        </TableDiv>
        <TableDiv>
          <TableDivFirst>
            Image Score
          </TableDivFirst>
          <TableDivSec>
          {analysisForm.image_score}
          </TableDivSec>
        </TableDiv>
        <TableDiv>
          <TableDivFirst>
            Bedrooms
          </TableDivFirst>
          <TableDivSec>
          {analysisForm.bedrooms}
          </TableDivSec>
        </TableDiv>
        <TableDiv>
          <TableDivFirst>
            Bathrooms
          </TableDivFirst>
          <TableDivSec>
          {analysisForm.bathrooms}
          </TableDivSec>
        </TableDiv>
        <TableDiv>
          <TableDivFirst>
            Beds
          </TableDivFirst>
          <TableDivSec>
          {analysisForm.beds}
          </TableDivSec>
        </TableDiv>
        <TableDiv>
          <TableDivFirst>
            Notable Features
          </TableDivFirst>
          <TableDivSec>
            Jacuzzi: {analysisForm.jacuzzi ? 'YES' : 'NO'}
            <FeatureDiv />
            Pool: {analysisForm.pool ? 'YES' : 'NO'}
            <FeatureDiv />
            Scenic View: {analysisForm.scenic_view ? 'YES' : 'NO'}
          </TableDivSec>
        </TableDiv>
        {/* <TableDiv>
          <TableDivFirst>
            Market Share
          </TableDivFirst>
          <TableDivSec>
            ???
          </TableDivSec>
        </TableDiv> */}
        <BodyTitle margin="24px 0 0 0">
          Property Benchmark Analysis
        </BodyTitle>
        <BodyText margin="24px 0 24px 0">
          Top 10 Market Comparables vs. Your Property
        </BodyText>
        <CompetitorList report={true} competitorList={scoutAnalysis?.intel_comp_options}/>
      </Page>
    </ReportPDFDiv>
  )
})

export default ReportPDF;
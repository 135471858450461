import React, { Fragment, useCallback,
  // useContext,
  useEffect, useState } from "react";
import styled from "styled-components";
import {
  CalendarOutlined,
  // SettingOutlined,
  DownloadOutlined,
  ProfileOutlined,
  // SyncOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { BodyText, BodyTitle } from 'components/ReusableComponents/Text/Text';
// import OwnerReportModal from "../../../../../revenue/propertyDetail/PropertyHeader/OwnerReportModal";
import OwnerReportModalV2 from "../../../../../revenue/propertyDetail/PropertyHeader/OwnerReportModalV2";

import guest from 'assets/images/market/guests.png';
import bath from 'assets/images/market/bath.png';
import bed from 'assets/images/market/bed.png';
import door from 'assets/images/market/door.png';
import yellowStar from 'assets/images/market/yellowstar.svg';
import purpleStar from 'assets/images/market/purplestar.svg';
import SkLoaderOver from "../../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver";
import { isEmpty, isNil } from "lodash";
import { DetailsDiv, EmptyBodyText } from "../../../../sharedComponents/CommonCSS";
// import { message } from 'antd';
//owner report related
import { useData } from '../../../../../../util/DataStore/DataContext';
import useMutation from '../../../../../../components/customHook/useMutation';
import { showName } from "../../../../../../util/helper";
// import { getData } from '../../../../../../util/localStorageHelper';
// import filters from '../../../../../../components/ReusableComponents/Filters/Filters';
// import { useDispatch,
  //  useSelector
  //  }
  //  from 'react-redux';
// import { PriceAdjustContext } from '../../../../../priceAdjustment/PriceAdjustment';
// import {
//   getMarketPricing,
//   getMarketPricingRules, getPortfolioPricing, getPortfolioPricingRules,
//   getPropertyPricing,
//   getPropertyPricingRules
// } from '../../../../../../appRedux/actions';


const ImageDiv = styled.img`
  min-width: 300px;
  min-height: 188px;
  border-radius: 6px;
  border: ${ ({ border }) => border };
`;

const PropertyInfoDiv = styled.div`
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 16px;
`;

const InfoDiv = styled.div`
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusText = styled.span`
  width: fit-content;
  padding: 2px 8px;
  border-radius: 100px;
  background: ${({$bgRed}) => $bgRed || '#DDFFE9'};
  color: ${({$colorRed}) => $colorRed || '#00A638'};
  margin: 0 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${ ({ theme }) => theme.s2 };
`;

const InfoBoxesDiv = styled(InfoDiv)`
  margin: 16px 0 0 0;
  gap: 16px;
`;

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid ${ ({ theme }) => theme.dirtyGrey };
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
`;

const InfoImage = styled.img`
  width: 36px;
  height: 36px;
  object-position: cover;
  image-rendering: -webkit-optimize-contrast;
  opacity: 0.7;
`;

const ImageContainerText = styled(BodyText)`
  color: ${ ({ theme }) => theme.quibbleFontGrey };
  margin: 8px 0 0 0;
`;

const InfoTextDiv = styled(InfoDiv)`
  margin: 16px 0 0 0;
`;

const SettingDiv = styled.button`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  color: ${ ({ theme }) => theme.quibbleFontGrey };
  margin: 0 0 0 4px;
  font-size: 18px;
  transition: all 0.1s linear;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
`;

const BlueSettingDiv = styled(SettingDiv)`
  color: ${ ({ theme }) => theme.quibbleBlue };
  border-radius: 4px;
  padding: 2px 4px;
  border: 1px solid ${ ({ theme }) => theme.quibbleBlue };

  &:hover {
    background: ${ ({ theme }) => theme.quibbleBlue };
    color: ${ ({ theme }) => theme.white };
    text-decoration: unset;
  }
`;

const InheritBodyText = styled(BodyText)`
  color: inherit;
  font-size: 16px;
`;

const ReservationsLink = styled(Link)`
  color: inherit;
`;

const PropSpan = styled.span`
  width: fit-content;
  background: ${ ({ theme }) => theme.quibbleBlueBg };
  color: ${ ({ theme }) => theme.quibbleBlue };
  font-size: ${ ({ theme }) => theme.s2 };
  border-radius: 4px;
  margin: 0 0 0 8px;
  padding: 2px;
`;

const PropertyDetailInfoDiv = styled(DetailsDiv)`
  min-width: 840px;
  max-width: 840px;
`;

const PropertyDetailInfo = ({ propertyDetails, monthYear, propertyId, isFetchingPropertyDetails }) => {
  // const { activeMarket, activeProperty } = useContext(PriceAdjustContext);
  // const dispatch = useDispatch();
  const [isNewFilter, setIsNewFilter] = useState(false);
  const { name, tumbnailUrl, isActive, rooms, baths, guests, beds, averageRating, averageMarketRating, isDynamicPricingEnabled  } = propertyDetails;
  const [isOwnerReportVisible, setIsOwnerReportVisible] = useState(false);
  // const authData = useSelector(({users}) => users.authUserDetails);
  const { state } = useData();

  const { request, loading, data, error, previousPayload } = useMutation({
    method: "POST",
    url: '/report',
    baseUri: 'report',
    onComplete: () => {
      setIsNewFilter(false)
    },
    onError: (e) => {
      console.log(e);
    }
  })

  const onCloseModal = useCallback(() => {
    setIsOwnerReportVisible(false);
  }, []);

  const onOwnerReportOpen = () => {
    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");
    // let persistedFilter = getData("filters") === null ? filters : getData("filters");
    const values = {
      userId: userId,
      token: token,
      remove_pms_charge: state?.remove_pms_charge,
      quarter: state?.quarter,
      propertyId: propertyId,
      year: monthYear.getFullYear(),
      month: monthYear.getMonth() + 1,
      viewType: state?.viewType,
    };
    request(values)
    setIsOwnerReportVisible(true);
  };


  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");
    // let persistedFilter = getData("filters") === null ? filters : getData("filters");
    const values = {
      userId: userId,
      token: token,
      remove_pms_charge: state?.remove_pms_charge,
      quarter: state?.quarter,
      propertyId: propertyId,
      year: monthYear.getFullYear(),
      month: monthYear.getMonth() + 1,
      viewType: state?.viewType,
    };

    if(JSON.stringify(values) !== JSON.stringify(previousPayload)) {
      setIsNewFilter(true)
    }
  }, [monthYear, state?.remove_pms_charge,state?.viewType, state?.quarter, previousPayload, propertyId]);

  const checkUpper  = (name?.match(/[A-Z]/g) || [])?.length;

  return (
    <PropertyDetailInfoDiv>
      { isEmpty(propertyDetails) ? <EmptyBodyText> No Data Found </EmptyBodyText> :
        <Fragment>
          <ImageDiv border={ tumbnailUrl ? 'none' : '1px solid black' } src={ tumbnailUrl } alt="Property Image"/>
          <PropertyInfoDiv>
            <InfoDiv>
              <BodyTitle>
                {checkUpper > 12 ? showName(name, 16) : showName(name, 22)}
              </BodyTitle>
                {isActive &&
                <StatusText>
                  Active
                </StatusText> }
                <StatusText $bgRed={!isDynamicPricingEnabled ? '#FFDEDE' : ''} $colorRed={!isDynamicPricingEnabled ? '#FA7D7D' : ''}>
                  Dynamic Pricing - {isDynamicPricingEnabled ? 'ON' : 'OFF'}
                </StatusText> 
            </InfoDiv>
            <InfoBoxesDiv>
              <ImageContainer>
                <InfoImage src={ guest } alt="guests"/>
                <ImageContainerText>{ `Guest: ${ guests || "-" }` }</ImageContainerText>
              </ImageContainer>
              <ImageContainer>
                <InfoImage src={ bath } alt="bath"/>
                <ImageContainerText>{ `Baths: ${ baths || "-" }` }</ImageContainerText>
              </ImageContainer>
              <ImageContainer>
                <InfoImage src={ bed } alt="bed"/>
                <ImageContainerText>{ `Beds: ${ beds || "-" }` }</ImageContainerText>
              </ImageContainer>
              <ImageContainer>
                <InfoImage src={ door } alt="door"/>
                <ImageContainerText>{ `Rooms: ${ rooms || "-" }` }</ImageContainerText>
              </ImageContainer>
            </InfoBoxesDiv>
            <InfoTextDiv>
              <BodyText margin="0 4px 0 0">{!isNil(averageRating) ? averageRating : '-'}</BodyText>
              <img src={ yellowStar } alt="yellow star"/>
              <BodyText margin="0 8px 0 8px">
                |
              </BodyText>
              <BodyText margin="0 4px 0 0">{!isNil(averageMarketRating) ? averageMarketRating?.toFixed(1) : '-'}</BodyText>
              <img src={ purpleStar } alt="purple star"/>
              <SettingDiv>
                <CalendarOutlined/>
                <InheritBodyText margin="0 0 0 4px">
                  <ReservationsLink to={ "/revenue/reservations?key=" + propertyId }>
                    Bookings
                  </ReservationsLink>
                </InheritBodyText>
              </SettingDiv>
              <SettingDiv>
                <ProfileOutlined/>
                <InheritBodyText margin="0 0 0 4px">
                  Attributes
                </InheritBodyText>
              </SettingDiv>
              {/* *** Lets bring in the Old Drawer from the old property module and connect to the Customizations Link here *** */ }
              {/* <SettingDiv>
                <SettingOutlined />
                <InheritBodyText margin="0 0 0 4px">
                  Customizations
                </InheritBodyText>
              </SettingDiv> */ }
              <BlueSettingDiv onClick={ onOwnerReportOpen }>
                <DownloadOutlined/>
                <InheritBodyText margin="0 0 0 4px">
                  Report
                </InheritBodyText>
                <PropSpan>
                  Pro
                </PropSpan>
              </BlueSettingDiv>
              {/* {authData.pms ==="Hostfully" && <BlueSettingDiv onClick={ () => {
                if (!syncing) {
                  requestSync()
                }
              } }>
                <SyncOutlined/>
                <InheritBodyText margin="0 0 0 3px">
                  { syncing ? "Please Wait..." : "Sync Calendar" }
                </InheritBodyText>
              </BlueSettingDiv> } */}
            </InfoTextDiv>
          </PropertyInfoDiv>
        </Fragment> }
        <OwnerReportModalV2
          isNewFilter={isNewFilter}
          error={ error } loading={ loading } propertyDetail={ data } updateFilter={ onOwnerReportOpen }
          isExportModalVisible={ isOwnerReportVisible } propertyId={ propertyId }
          onSubmit={ onCloseModal } onCloseModal={ onCloseModal } month={ monthYear.getMonth() + 1 }
          year={ monthYear.getFullYear() }
          />
        {/* <OwnerReportModal error={ error } loading={ loading } propertyDetail={ data } updateFilter={ onOwnerReportOpen }
                        isExportModalVisible={ isOwnerReportVisible } propertyId={ propertyId }
                        onSubmit={ onCloseModal } onCloseModal={ onCloseModal } month={ monthYear.getMonth() + 1 }
                        year={ monthYear.getFullYear() }/> */}
      <SkLoaderOver isLoading={ isFetchingPropertyDetails || isEmpty(propertyDetails) } type="description"/>
    </PropertyDetailInfoDiv>
  )
}

export default PropertyDetailInfo;

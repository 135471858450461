import {
  USER_PROPERTY_LIST,
  USER_PROPERTY_LIST_SUCCESS,
  USER_PROPERTY_YEARLY_DATA,
  USER_PROPERTY_YEARLY_DATA_SUCCESS,
  UPDATE_PROPERTY_STATUS,
  UPDATE_PROPERTY_STATUS_SUCCESS,
  GET_PROPERTY_DETAIL,
  SET_PROPERTY_DETAIL,
  DOWNLOAD_OWNER_REPORT,
  GET_PROPERTY_GROUPS,
  GET_PROPERTY_GROUP_BY_ID,
  GET_PROPERTY_GROUP_BY_ID_SUCCESS,
  GET_PROPERTY_GROUPS_LISTINGS,
} from "constants/ActionTypes";
import { GET_CALENDAR_LISTINGS, GET_CITY_ID, GET_PROPERTIES_LISTS, GET_PROPERTIES_LISTS_SUCCESS, GET_PROPERTIES_RESERVATIONS, GET_PROPERTY_ANALYTICS, GET_PROPERTY_CALENDAR, GET_PROPERTY_DETAILS, GET_PROPERTY_MODEL, GET_PROPERTY_COMPETITORS, GET_PROPERTY_OVERVIEW_METRICS, GET_PROPERTY_PRICING, GET_PROPERTY_TABLE_METRICS, GET_SYNC_DATA, HIDE_MESSAGE, REMOVE_BUILDING_TAGS, SET_CITY_ID, SET_PROPERTIES_RESERVATIONS, SET_SYNC_DATA, UPDATE_BUILDING_TAGS, UPDATE_DISTRIBUTION_CHANNEEL, UPDATE_PROPERTY_LOGS, GET_PROPERTY_PRICING_RULES, GET_PROPERTY_QUEUE, SET_WORKING_QUEUE, GET_ACTIONABLE_QUEUE, GET_PROPERTY_OVERVIEW_CHART, GET_PROPERTY_SCOUT_SEARCH, GET_PROPERTY_SCOUT_PROPERTIES } from "../../constants/ActionTypes";



/*** property list */
export const getPropertyList = (request) => {
  return {
    type    : USER_PROPERTY_LIST,
    payload : request
  };
};

/*** property list success*/
export const updatePropertyList = (response) => {
  return {
    type: USER_PROPERTY_LIST_SUCCESS,
    payload: response
  };
};

/*** user data sync */
export const userDataSync = (request) => {
  return {
    type: GET_SYNC_DATA,
    payload: request,
  };
};

/*** user data sync */
export const updateUserDataSync = (response) => {
  return {
    type: SET_SYNC_DATA,
    payload: response,
  };
};

/*** get city Ids */
export const getCityIds = (request) => {
  return {
    type    : GET_CITY_ID,
    payload : request
  };
};

/*** city ids success*/
export const updateCityIds = (response) => {
  return {
    type: SET_CITY_ID,
    payload: response
  };
};

/*** Reservation list */
export const getPropertiesReservations = (request) => {
  return {
    type    : GET_PROPERTIES_RESERVATIONS,
    payload : request
  };
};

/*** Reservation list success*/
export const updatePropertiesReservations = (response) => {
  return {
    type: SET_PROPERTIES_RESERVATIONS,
    payload: response
  };
};


/*** property listing list */
export const getPropertyLists = (request) => {
  return {
    type    : GET_PROPERTIES_LISTS,
    payload : request
  };
};

/*** property listing list success*/
export const updatePropertyLists = (response) => {
  return {
    type: GET_PROPERTIES_LISTS_SUCCESS,
    payload: response
  };
};


/*** Get last and current year data */
export const getCurrentAndLastYearData = (request) => {
  return {
    type    : USER_PROPERTY_YEARLY_DATA,
    payload : request
  };
};

/*** set last and current year data */
export const setCurrentAndLastYearData = (response) => {
  return {
    type: USER_PROPERTY_YEARLY_DATA_SUCCESS,
    payload: response
  };
};



/*** update property status*/
export const updatePropertyStatus = (request) => {
  return {
    type: UPDATE_PROPERTY_STATUS,
    payload: request
  };
};

/*** Get property detail */
export const getPropertyDetail = (request) => {
  return {
    type: GET_PROPERTY_DETAIL,
    payload: request
  };
};

/*** Set property detail */
export const setPropertyDetail = (request) => {
  return {
    type: SET_PROPERTY_DETAIL,
    payload: request
  };
};


/*** update property status success*/
export const showPropertyMessage = (response) => {
  return {
    type: UPDATE_PROPERTY_STATUS_SUCCESS,
    payload: response
  };
};

/*** update property status success*/
export const hidePropertyMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};

/*** update property status success*/
export const updatePropertyLogs = (response) => {
  return {
    type: UPDATE_PROPERTY_LOGS,
    payload: response
  };
};

export const updateDistributionsIds = (request) => {
  console.log("actions payload >> ", request)
  return {
    type: UPDATE_DISTRIBUTION_CHANNEEL,
    payload: request
  };
};

/*** update property building tags*/
export const updateBuildingTags = (response) => {
  return {
    type: UPDATE_BUILDING_TAGS,
    payload: response
  };
};

/*** remove property building tags*/
export const removeBuildingTags = (response) => {
  return {
    type: REMOVE_BUILDING_TAGS,
    payload: response
  };
};

export const downloadOwnerReport = (request) => {
  return {
    type: DOWNLOAD_OWNER_REPORT,
    payload: request,
  }
}

export const getCalendarListings = (values) => {
  return {
    type: GET_CALENDAR_LISTINGS,
    payload: values,
  }
}

export const getPropertyCalendar = (values) => {
  return {
    type: GET_PROPERTY_CALENDAR,
    payload: values,
  }
};

export const getPropertyPricing = (values) => {
  return {
    type: GET_PROPERTY_PRICING,
    payload: values,
  }
};

export const getPropertyDetails = (values) => {
  return {
    type: GET_PROPERTY_DETAILS,
    payload: values,
  }
};

export const getPropertyOverviewMetrics = (values) => {
  return {
    type: GET_PROPERTY_OVERVIEW_METRICS,
    payload: values,
  }
};

export const getPropertyTableMetrics = (values) => {
  return {
    type: GET_PROPERTY_TABLE_METRICS,
    payload: values,
  }
};

export const getPropertyModel = (values) => {
  return {
    type: GET_PROPERTY_MODEL,
    payload: values,
  }
};

export const getPropertyCompetitors = (values) => {
  return {
    type: GET_PROPERTY_COMPETITORS,
    payload: values,
  }
};

export const getPropertyAnalytics = (values) => {
  return {
    type: GET_PROPERTY_ANALYTICS,
    payload: values,
  }
};

export const getPropertyPricingRules = (values) => {
  return {
    type: GET_PROPERTY_PRICING_RULES,
    payload: values,
  }
};

export const getPropertyQueue = (values) => {
  return {
    type: GET_PROPERTY_QUEUE,
    payload: values,
  }
};

export const setWorkingQeue = (values) => {
  return {
    type: SET_WORKING_QUEUE,
    payload: values,
  }
};

export const getActionableQueue = (values) => {
  return {
    type: GET_ACTIONABLE_QUEUE,
    payload: values,
  }
}; 

// Property groups
export const getPropertyGroups = (values) => ({
  type: GET_PROPERTY_GROUPS,
  payload: values
});

export const getPropertyGroupById = (propertyGroupById) => ({
  type: GET_PROPERTY_GROUP_BY_ID,
  payload: propertyGroupById,
});

export const getPropertyGroupByIdSuccess = (propertyGroup) => ({
  type: GET_PROPERTY_GROUP_BY_ID_SUCCESS,
  payload: propertyGroup,
});

export const getPropertyGroupsListings = (values) => ({
  type: GET_PROPERTY_GROUPS_LISTINGS,
  payload: values
});

export const getPropertyOverviewChart = (values) => {
  return {
    type: GET_PROPERTY_OVERVIEW_CHART,
    payload: values,
  }};

export const getPropertyScoutSearch = (values) => {
  return {
    type: GET_PROPERTY_SCOUT_SEARCH,
    payload: values,
}};

export const getPropertyScoutProperties = (values) => {
  return {
    type: GET_PROPERTY_SCOUT_PROPERTIES,
    payload: values,
}};


import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { LayoutBody } from '../../../../components/ReusableComponents/Layout/LayoutBody';
import {BodyTitle } from '../../../../components/ReusableComponents/Text/Text';
import AdjustMetrics from './AdjustMetrics';
import AnalysisResults from './AnalysisResults';
import { darkScrollbar, lightScrollbar } from '../../../../components/ReusableComponents/Scrollbar/Scrollbar';
import { PropertyScoutContext } from '..';
import ReportPDF from '../Reports/ReportPDF';
import { useReactToPrint } from 'react-to-print';


const PropertyScoutBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: ${({justification}) => justification};
  // justify-content: ${({justification}) => justification};
  justify-content: flex-start;
  overflow: auto;
  padding: 12px;

  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;


const ScoutAnalysis = () => {

  const {analysing} = useContext(PropertyScoutContext);

  const componentRef = useRef(null);
  
 const onPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <PropertyScoutBody justification={analysing ? 'flex-start' : 'center'}>
      {!analysing ? <BodyTitle margin={analysing ? "0 0 56px 0" : "64px 0 56px 0"}>
        Let’s play around with the settings and see how much money this property could make.
      </BodyTitle> : null}
      {analysing ? <AnalysisResults onPrint={onPrint}  /> : <AdjustMetrics  />}
      <ReportPDF ref={componentRef} /> 
    </PropertyScoutBody>
  )
}

export default ScoutAnalysis;
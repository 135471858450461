import React, { useEffect } from 'react';
import styled from 'styled-components';
import { LayoutBody } from '../../../../components/ReusableComponents/Layout/LayoutBody';
import { BodyTitle } from '../../../../components/ReusableComponents/Text/Text';
import QuibbleTable from '../../../../components/ReusableComponents/Table/QuibbleTable';
import { darkScrollbar, lightScrollbar } from '../../../../components/ReusableComponents/Scrollbar/Scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertyScoutProperties } from '../../../../appRedux/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useContext } from 'react';
import { PropertyScoutContext } from '..';

const PropertyScoutBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items:center;
  overflow: auto;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const PropertyScoutTableBody = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  box-sizing: border-box;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const PropertyTableParentDiv = styled.div`
  min-width: 1800px;
  height: fit-content;
  max-width: 1800px;
  margin: 0 0 0 0;
`;

const PropertyTitle = styled(BodyTitle)`
  width: 100%;
  max-width: 1800px;
  font-size: 2rem;
  margin: 16px 0 ;
`;

const ViewButton = styled.button`
  width: 60px;
  border: none;
  height: 32px;
  outline: none;
  background: #00BBD2;
  text-align: center;
  cursor: pointer;
  color: white;
  border-radius: 4px;
  transition: all 0.1s linear;

  &:hover{
    color: #00BBD2;
    background: white;
    border: 1px solid #00BBD2;
  }
`;

const ImageName = styled.img`
  width: 58px;
  height: 40px;
  margin: 0 8px 0 0;
`;


const ScoutProperties = () => {

  const { propertyScoutProperties } = useSelector(({ property }) => property);
  const {setScoutAnalysis, setAnalysisForm, setAnalysing} = useContext(PropertyScoutContext);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getPropertyScoutProperties())
  }, [dispatch])

  const scoutPropertyColumn = [ {
    label: 'property',
    width: '600px',
    align: 'left',
    customRender:(data) => {
      return (
        <>
          {data?.image_thumbnail ? <ImageName src={data?.image_thumbnail} />   : null}
          <span>
            {data?.address || "N/A"}
          </span>
        </>
      )
    }
  },
  {
    label: 'analysis started on',
    width: '400px',
    align: 'right',
    dataIndex: 'created_at',
    customRender: (data) => {
      const date = new Date(data);
      const defaultMonth = date.getMonth() + 1;
      const defaultDay = date.getDate();
      const month =  defaultMonth > 10 ? defaultMonth : `0${defaultMonth}`;
      const day =  defaultDay > 10 ? defaultDay : `0${defaultDay}`;
      return `${date.getFullYear()}/${month}/${day}`
    }
  },
  {
    label: 'status',
    width: '400px',
    align: 'right',
    dataIndex: 'status'
  },
  {
    label: 'action',
    width: '200px',
    align: 'center',
    customRender: (data) => {

      if (data?.status === 'done') {
        const onCLick = () => {
          setAnalysisForm({
            price_per_night: data?.price_per_night,
            bedrooms: data?.bedrooms,
            bathrooms: data?.bathrooms,
            beds: data?.beds,
            jacuzzi: data?.beds,
            pool: data?.pool,
            scenic_view: data?.scenic_view,
            images: data?.image_thumbnail,
            image_score: data?.weighted_avg_score.toFixed(2),
          })

          setScoutAnalysis({
            display_name: data?.address || 'Scouted Property',
            lat: data?.latitude,
            lon: data?.longitude,
            intel_comp_options: data?.intel_comp_options,
            monthly_expected_revenue: data?.monthly_expected_revenue,
          })

          setAnalysing(true)
          history.push("/businessTools/property-scout/analysis");
        };
  
        return(
          <ViewButton onClick={onCLick}>
            View
          </ViewButton>
        )
      }
      return '-'
    }    
  },]

  return (
    <PropertyScoutBody>
      <PropertyTitle>
        Properties
      </PropertyTitle>
      <PropertyScoutTableBody>
        <PropertyTableParentDiv>
          <QuibbleTable columns={ scoutPropertyColumn } data={ propertyScoutProperties } withCheckBox={ true } pagination={ true } paginationSize={ 10 }/>
        </PropertyTableParentDiv>
      </PropertyScoutTableBody>
    </PropertyScoutBody>
  )
}

export default ScoutProperties;
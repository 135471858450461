import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { AuthItemDiv, AuthTitle, ImgProp } from '../../sharedComponents/CommonCss';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import clip from 'assets/images/generic/clip.svg'
import PMSModal from '../subComponentes/PMS/PMSModal';

import ownerrez from 'assets/images/pms/ownerrez.png';
import hostaway from 'assets/images/pms/hostaway.png';
import guesty from 'assets/images/pms/guesty.png';
import hostfully from 'assets/images/pms/hostfully.png';
import villas365 from 'assets/images/pms/365villas.logo.svg';
import lodgify from 'assets/images/pms/lodgify.png';
import hospitable from 'assets/images/pms/hospitable.png';
import escapia from 'assets/images/pms/logo_escapia.png';




import OwnerrezComp from '../subComponentes/PMS/Ownerrez';
// import NoPMS from '../subComponentes/PMS/NoPMS';
import Guesty from '../subComponentes/PMS/Guesty';
import GuestyWithID from '../subComponentes/PMS/GuestyWithId';
import HostAway from '../subComponentes/PMS/HostAway';
import Hostfully from '../subComponentes/PMS/Hostfully';
import Villas365 from '../subComponentes/PMS/365villas';
import Escapia from '../subComponentes/PMS/Escapia';
import { auth } from '../../../../services/auth';
import { message } from 'antd';
import Lodgify from '../subComponentes/PMS/Lodgify';
import Hospitable from '../subComponentes/PMS/Hospitable';

const PMSDiv = styled.div`
  ${AuthItemDiv}
`;

const SelectPMSButton = styled.button`
  width: 460px;
  height: 70px;
  background: none;
  border: 1px solid #CDCDCD;
  border-radius: 4px;
  cursor: pointer;
  margin: 54px 0 0 0;
  position: relative;
`;

const ClipImg = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
  margin: auto;
`;


const PMSImages = {
  '':  <BodyText> Choose Property Management System </BodyText>,
  OwnerRez: <ImgProp src={ownerrez} alt='ownerrez' width='148px' height='24px'/>,
  Guesty: <ImgProp src={guesty} alt='guesty' width='154px' height='40px'/>,
  guestyWithID :<ImgProp src={guesty} alt='guesty' width='154px' height='40px'/>,
  Hostaway: <ImgProp src={hostaway} alt='hostaway' width='132px' height='32px'/>,
  Hostfully: <ImgProp src={hostfully} alt='hostfully' width='124px' height='32px'/>,
  "365Villas": <ImgProp src={villas365} alt='365Villas' width='124px' height='32px'/>,
  Escapia: <ImgProp src={escapia} alt='escapia' width='124px' height='32px'/>,
  Lodgify: <ImgProp src={lodgify} alt='lodgify' width='140px' height='28px'/>,
  Hospitable: <ImgProp src={hospitable} alt='hospitable' width='188px' height='32px'/>,
}

const PMS = ({signupString, setAuthMenuFunc}) => {

  const [modalVisible, setModalVisble] = useState(false);
  const [PMS, setPMS] = useState('');
  // const [noPMSChecked, setNoPMSChecked] = useState(false);

  const onModalClick = () => {
    setModalVisble((prev) => !prev);
  };

  const onCloseModal = () => {
    setModalVisble(false);
  };

  const onChangePMS = useCallback((value) => {
    setPMS(value);
  }, []);


  const onSubmitForm = async(values) => {
    try {

      const dispatchPMS = PMS === 'guestyWithID' ? 'Guesty' : PMS;
      const updateProfile = await auth.profileSetup({
        ...values,
        pms: dispatchPMS,
        type: dispatchPMS,
        string: signupString,
      })

      if (updateProfile.data.status){
        message.success('Update PMS successful');
        setAuthMenuFunc('finish')
        return;
      };

      message.error(updateProfile.data.errors.errors[0] || 'Error on update PMS attempt')

    } catch (e) {
      message.error('Update PMS error. Please try again')
    }

   }

  const PMSContent = {
    OwnerRez: <OwnerrezComp signupString={signupString} PMS={PMS} setAuthMenuFunc={setAuthMenuFunc}/>,
    Guesty: <Guesty onSubmitForm={onSubmitForm} onChangePMS={onChangePMS}/>,
    guestyWithID: <GuestyWithID  onSubmitForm={onSubmitForm} onChangePMS={onChangePMS}/>,
    Hostaway: <HostAway onSubmitForm={onSubmitForm}/>,
    Hostfully: <Hostfully onSubmitForm={onSubmitForm}  />,
    "365Villas": <Villas365 onSubmitForm={onSubmitForm}  />,
    Lodgify: <Lodgify onSubmitForm={onSubmitForm}/>,
    Escapia: <Escapia stepOne onSubmitForm={onSubmitForm}/>,
    Hospitable: <Hospitable signupString={signupString} PMS={PMS} setAuthMenuFunc={setAuthMenuFunc}/>,
  };


  return (
    <PMSDiv>
      <AuthTitle>
        Connect Your PMS
      </AuthTitle>
      <SelectPMSButton onClick={onModalClick}>
        {PMSImages[PMS]}
        <ClipImg src={clip} alt='clip' />
      </SelectPMSButton>
      {PMSContent[PMS]}
      {modalVisible && <PMSModal modalVisible={modalVisible} onCloseModal={onCloseModal} onChangePMS={onChangePMS}/>}
    </PMSDiv>
  )
}

export default PMS;

// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const SHOW_ERROR_MESSAGES = "SHOW_ERROR_MESSAGES";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const SELECTED_PMS = "SELECTED_PMS";
export const SELECTED_PMS_SUCCESS = "SELECTED_PMS_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNUP_GOOGLE_USER = "SIGNUP_GOOGLE_USER";
export const SIGNUP_GOOGLE_USER_SUCCESS = "SIGNUP_GOOGLE_USER_SUCCESS";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const API_KEY_PMS_SUCCESS = "API_KEY_PMS_SUCCESS";

//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";

//Contact
export const GET_All_CONTACT_SUCCESS = "GET_All_CONTACT_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS = "ON_ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";

/**** Dashboard */
export const USER_DASHBOARD = "USER_DASHBOARD";
export const USER_DASHBOARD_SUCCESS = "export const";

/*** Property  */
export const GET_PROPERTY_DETAIL = "GET_PROPERTY_DETAIL";
export const SET_PROPERTY_DETAIL = "SET_PROPERTY_DETAIL";
export const USER_PROPERTY_LIST = "USER_PROPERTY_LIST";
export const USER_PROPERTY_LIST_SUCCESS = "USER_PROPERTY_LIST_SUCCESS";
export const UPDATE_PROPERTY_STATUS = "UPDATE_PROPERTY_STATUS";
export const UPDATE_PROPERTY_STATUS_SUCCESS = "UPDATE_PROPERTY_STATUS_SUCCESS";
export const GET_PROPERTIES_LISTS = "GET_PROPERTIES_LISTS";
export const GET_PROPERTIES_LISTS_SUCCESS = "GET_PROPERTIES_LISTS_SUCCESS";
export const UPDATE_PROPERTY_DETAIL = "UPDATE_PROPERTY_DETAIL";
export const USER_PROPERTY_YEARLY_DATA = "USER_PROPERTY_YEARLY_DATA";
export const USER_PROPERTY_YEARLY_DATA_SUCCESS =
  "USER_PROPERTY_YEARLY_DATA_SUCCESS";
export const UPDATE_PROPERTY_LOGS = "UPDATE_PROPERTY_LOGS";
export const DOWNLOAD_OWNER_REPORT = "DOWNLOAD_OWNER_REPORT";
export const DOWNLOAD_OWNER_REPORT_SUCCESS = "DOWNLOAD_OWNER_REPORT_SUCCESS";
export const GET_CALENDAR_LISTINGS = "GET_CALENDAR_LISTINGS";
export const GET_CALENDAR_LISTINGS_SUCCESS = "GET_CALENDAR_LISTINGS_SUCCESS";
export const GET_PROPERTY_CALENDAR = 'GET_PROPERTY_CALENDAR';
export const GET_PROPERTY_CALENDAR_SUCCESS = 'GET_PROPERTY_CALENDAR_SUCCESS';
export const GET_PROPERTY_PRICING = 'GET_PROPERTY_PRICING';
export const GET_PROPERTY_PRICING_SUCCESS = 'GET_PROPERTY_PRICING_SUCCESS';
export const GET_MARKET_PRICING = 'GET_MARKET_PRICING';
export const GET_MARKET_PRICING_SUCCESS = 'GET_MARKET_PRICING_SUCCESS';
export const GET_PORTFOLIO_PRICING = 'GET_PORTFOLIO_PRICING';
export const GET_PORTFOLIO_PRICING_SUCCESS = 'GET_PORTFOLIO_PRICING_SUCCESS';
export const GET_PROPERTY_DETAILS = 'GET_PROPERTY_DETAILS';
export const GET_PROPERTY_DETAILS_SUCCESS = 'GET_PROPERTY_DETAILS_SUCCESS';
export const GET_PROPERTY_OVERVIEW_METRICS = 'GET_PROPERTY_OVERVIEW_METRICS';
export const GET_PROPERTY_OVERVIEW_METRICS_SUCCESS = 'GET_PROPERTY_OVERVIEW_METRICS_SUCCESS';
export const GET_PROPERTY_TABLE_METRICS = 'GET_PROPERTY_TABLE_METRICS';
export const GET_PROPERTY_TABLE_METRICS_SUCCESS = 'GET_PROPERTY_TABLE_METRICS_SUCCESS';
export const GET_PROPERTY_MODEL = 'GET_PROPERTY_MODEL';
export const GET_PROPERTY_MODEL_SUCCESS = 'GET_PROPERTY_MODEL_SUCCESS';
export const GET_PROPERTY_COMPETITORS = 'GET_PROPERTY_COMPETITORS';
export const GET_PROPERTY_COMPETITORS_SUCCESS = 'GET_PROPERTY_COMPETITORS_SUCCESS';
export const GET_PROPERTY_ANALYTICS = 'GET_PROPERTY_ANALYTICS';
export const GET_PROPERTY_ANALYTICS_SUCCESS = 'GET_PROPERTY_ANALYTICS_SUCCESS';
export const GET_PROPERTY_PRICING_RULES = 'GET_PROPERTY_PRICING_RULES';
export const GET_PROPERTY_PRICING_RULES_SUCCESS = 'GET_PROPERTY_PRICING_RULES_SUCCESS';
export const GET_PORTFOLIO_PRICING_RULES = 'GET_PORTFOLIO_PRICING_RULES';
export const GET_PORTFOLIO_PRICING_RULES_SUCCESS = 'GET_PORTFOLIO_PRICING_RULES_SUCCESS';
export const GET_PROPERTY_QUEUE = 'GET_PROPERTY_QUEUE';
export const GET_PROPERTY_QUEUE_SUCCESS = 'GET_PROPERTY_QUEUE_SUCCESS';
export const SET_WORKING_QUEUE = 'SET_WORKING_QUEUE';
export const SET_WORKING_QUEUE_DATA = 'SET_WORKING_QUEUE_DATA';
export const GET_ACTIONABLE_QUEUE = 'GET_ACTIONABLE_QUEUE';
export const GET_ACTIONABLE_QUEUE_SUCCESS = 'GET_ACTIONABLE_QUEUE_SUCCESS';
export const GET_PROPERTY_OVERVIEW_CHART = 'GET_PROPERTY_OVERVIEW_CHART';
export const GET_PROPERTY_OVERVIEW_CHART_SUCCESS = 'GET_PROPERTY_OVERVIEW_CHART_SUCCESS';
export const GET_PROPERTY_SCOUT_SEARCH = 'GET_PROPERTY_SCOUT_SEARCH';
export const GET_PROPERTY_SCOUT_SEARCH_SUCCESS = 'GET_PROPERTY_SCOUT_SEARCH_SUCCESS';
export const GET_PROPERTY_SCOUT_PROPERTIES = 'GET_PROPERTY_SCOUT_PROPERTIES';
export const GET_PROPERTY_SCOUT_PROPERTIES_SUCCESS = 'GET_PROPERTY_SCOUT_PROPERTIES_SUCCESS';


// export const ADD_PROPERTY_QUEUE = 'ADD_PROPERTY_QUEUE';
// export const ADD_PROPERTY_QUEUE_SUCCESS = 'ADD_PROPERTY_QUEUE_SUCCESS';
// export const DELETE_PROPERTY_QUEUE = 'DELETE_PROPERTY_QUEUE';
// export const DELETE_PROPERTY_QUEUE_SUCCESS = 'DELETE_PROPERTY_QUEUE_SUCCESS';
/** Markets */
export const USER_MARKETS_LIST = "USER_MARKETS_LIST";
export const USER_MARKETS_LIST_SUCCESS = "USER_MARKETS_LIST_SUCCESS";
export const USER_MARKETS_YEARLY_DATA = "USER_MARKETS_YEARLY_DATA";
export const USER_MARKETS_YEARLY_DATA_SUCCESS =
  "USER_MARKETS_YEARLY_DATA_SUCCESS";
export const UPDATE_MARKETS_STATUS = "UPDATE_MARKETS_STATUS";
export const UPDATE_MARKETS_STATUS_SUCCESS = "UPDATE_MARKETS_STATUS_SUCCESS";
export const GET_MARKETS_DETAIL = "GET_MARKETS_DETAIL";
export const SET_MARKETS_DETAIL = "SET_MARKETS_DETAIL";
export const GET_MARKET_DETAILS = 'GET_MARKET_DETAILS';
export const GET_MARKET_DETAILS_SUCCESS = 'GET_MARKET_DETAILS_SUCCESS';
export const GET_MARKET_ANALYTICS = "GET_MARKET_ANALYTICS";
export const GET_MARKET_ANALYTICS_SUCCESS = 'GET_MARKET_ANALYTICS_SUCCESS';
export const GET_MARKET_PROPERTY_TABLE = "GET_MARKET_PROPERTY_TABLE";
export const GET_MARKET_PROPERTY_TABLE_SUCCESS = 'GET_MARKET_PROPERTY_TABLE_SUCCESS';
export const GET_MARKET_PRICING_RULES = 'GET_MARKET_PRICING_RULES';
export const GET_MARKET_PRICING_RULES_SUCCESS = 'GET_MARKET_PRICING_RULES_SUCCESS';
export const GET_LISTINGS = 'GET_LISTINGS';
export const GET_LISTINGS_SUCCESS = 'GET_LISTINGS_SUCCESS';


/* Events */
export const GET_EVENTS_TABLE = 'GET_EVENTS_TABLE';
export const GET_EVENTS_TABLE_SUCCESS = 'GET_EVENTS_TABLE_SUCCESS';

export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS';
export const GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS';

export const UPDATE_EVENT_DETAILS = "UPDATE_EVENT_DETAILS";
export const UPDATE_EVENT_DETAILS_SUCCESS = "UPDATE_EVENT_DETAILS";

export const DELETE_EVENT_DETAILS = "DELETE_EVENT_DETAILS";


/*** Reservation */
export const USER_RESERVATION_LIST = "USER_RESERVATION_LIST";
export const USER_RESERVATION_LIST_SUCCESS = "USER_RESERVATION_LIST_SUCCESS";
export const USER_BOOKING_FEED = "USER_BOOKING_FEED";
export const USER_BOOKING_FEED_SUCCESS = "USER_BOOKING_FEED_SUCCESS";

export const USER_RATES_LIST = "USER_RATES_LIST";
export const USER_RATES_LIST_SUCCESS = "USER_RATES_LIST_SUCCESS";

export const GET_PROPERTIES_RESERVATIONS = "GET_PROPERTY_RESERVATIONS";
export const SET_PROPERTIES_RESERVATIONS = "SET_PROPERTY_RESERVATIONS";

/*** For User Details */
export const GET_LOGIN_USER_DETAILS = "GET_LOGIN_USER_DETAILS";
export const GET_LOGIN_USER_DETAILS_SUCCESS = "GET_LOGIN_USER_DETAILS_SUCCESS";
export const USER_UPDATE_PROFILE = "USER_UPDATE_PROFILE";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";

export const GET_USERS_LISTING = "GET_USERS_LISTING";
export const GET_USERS_LISTING_SUCCESS = "GET_USERS_LISTING_SUCCESS";

export const USER_PROFILE_ERROR_MESSAGES = "USER_PROFILE_ERROR_MESSAGES";
export const USER_PROFILE_NOTIFY_ERROR_MESSAGES =
  "USER_PROFILE_NOTIFY_ERROR_MESSAGES";

export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";

export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";

export const FIVE_HUNDRED_ERROR = "FIVE_HUNDRED_ERROR";
export const FIVE_HUNDRED_ERROR_SUCCESS = "FIVE_HUNDRED_ERROR_SUCCESS";
export const SET_EMAILFREQUENCY = "SET_EMAILFREQUENCY";

export const GET_USER_METRICS_SUMMARY = "GET_USER_METRICS_SUMMARY";
export const GET_USER_METRICS_SUMMARY_SUCCESS = "GET_USER_METRICS_SUMMARY_SUCCESS";

export const GET_USER_PORTFOLIO = "GET_USER_PORTFOLIO";
export const GET_USER_PORTFOLIO_SUCCESS = "GET_USER_PORTFOLIO_SUCCESS";

export const UPDATE_USER_PMS = "UPDATE_USER_PMS";
export const UPDATE_USER_PMS_SUCCESS = "UPDATE_USER_PMS_SUCCESS";


/*** For Country List */

export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";

export const GET_INSIGHT_MODAL_TYPE = "GET_INSIGHT_MODAL_TYPE";
export const SET_INSIGHT_MODAL_TYPE = "SET_INSIGHT_MODAL_TYPE";

/*** For Revenue Planning */
export const GET_REVENUE_PLANNING = "GET_REVENUE_PLANNING";
export const SET_REVENUE_PLANNING = "SET_REVENUE_PLANNING";
export const GET_REVENUE_PLANS = "GET_REVENUE_PLANS";
export const SET_REVENUE_PLANS = "SET_REVENUE_PLANS";
export const GET_CURRENT_REVENUE_PLAN = "GET_CURRENT_REVENUE_PLAN";
export const SET_CURRENT_REVENUE_PLAN = "SET_CURRENT_REVENUE_PLAN_SUCCESSFUL";
export const CREATE_REVENUE_PLAN = "CREATE_REVENUE_PLAN";
export const EDIT_REVENUE_PLAN = "EDIT_REVENUE_PLAN";
export const DELETE_REVENUE_PLAN = "DELETE_REVENUE_PLAN";


export const MARKET_WATCH = "MARKET_WATCH";
export const LISTINGS_WATCH = "LISTINGS_WATCH";
export const LISTINGS_WATCH_BUILDING = "LISTINGS_WATCH_BUILDING";
export const NOTIFICATION_WATCH = "NOTIFICATION_WATCH";
export const NOTIFICATION_WATCH_SUCCESS = "NOTIFICATION_WATCH_SUCCESS";
export const MARKET_WATCH_SUCCESS = "MARKET_WATCH_SUCCESS";
export const LISTINGS_WATCH_SUCCESS = "LISTINGS_WATCH_SUCCESS";
export const LISTINGS_WATCH_BUILDING_SUCCESS = "LISTINGS_WATCH_BUILDING_SUCCESS";


export const UPDATE_BUILDING_TAGS = "UPDATE_BUILDING_TAGS";
export const UPDATE_DISTRIBUTION_CHANNEEL = "UPDATE_DISTRIBUTION_CHANNEEL";
export const REMOVE_BUILDING_TAGS = "REMOVE_BUILDING_TAGS";

/** Filters */
export const SET_FILTERS = "SET_FILTERS";
export const SET_PARENT_FILTER = "SET_PARENT_FILTER";


/*** For CHARGEBEE */

export const CHECKOUT_NEW_SUBSCRIPTION = "CHECKOUT_NEW_SUBSCRIPTION";
export const CHECKOUT_NEW_SUBSCRIPTION_SUCCESS = "CHECKOUT_NEW_SUBSCRIPTION_SUCCESS";
export const UPGRATE_SUBSCRIPTION = "UPGRATE_SUBSCRIPTION";
export const UPGRATE_SUBSCRIPTION_SUCESS = "UPGRATE_SUBSCRIPTION_SUCESS";
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_LISTING = "GET_SUBSCRIPTION_LISTING";

export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const GET_CUSTOMERS_LISTING = "GET_CUSTOMERS_LISTING";

export const GET_PLAN_LISTING = "GET_PLAN_LISTING";


/** BUSINESS TOOLS (Market Hunter) */

export const GET_CITY_DETAILS =  "GET_CITY_DETAILS";
export const GET_CITY_DETAILS_V2 =  "GET_CITY_DETAILS_V2";
export const SET_CITY_DETAILS =  "SET_CITY_DETAILS";
export const SEARCH_CITY = "SEARCH_CITY";
export const SEARCH_CITY_V2 = "SEARCH_CITY_V2";
export const SET_SEARCHED_CITIES = "SET_SEARCHED_CITIES";

export const GET_CALENDAR_METRIC = "GET_CALENDAR_METRIC";
export const SET_CALENDAR_METRIC = "SET_CALENDAR_METRIC";

export const GET_CITY_ID = "GET_CITY_ID";
export const SET_CITY_ID = "SET_CITY_ID";

export const SUBSCRIBE = "SUBSCRIBE";
export const MANAGE_SUBSCRIPTIONS = "MANAGE_SUBSCRIPTIONS";
export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS";
export const GET_SUBSCRIPTION_PLANS_COMPLETE = "GET_SUBSCRIPTION_PLANS_COMPLETE";
export const GET_SUBSCRIPTION_CARDS = "GET_SUBSCRIPTION_CARDS";
export const GET_SUBSCRIPTION_CARDS_COMPLETE = "GET_SUBSCRIPTION_CARDS_COMPLETE";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_COMPLETE = "GET_TRANSACTIONS_COMPLETE";

export const GET_SYNC_DATA = "GET_SYNC_DATA";
export const SET_SYNC_DATA = "SET_SYNC_DATA";

export const GET_PORTFOLIO_GOALS = "GET_PORTFOLIO_GOALS";
export const GET_PORTFOLIO_GOALS_SUCCESS = 'GET_PORTFOLIO_GOALS_SUCCESS';
export const GET_MARKET_GOALS = "GET_MARKET_GOALS";
export const GET_MARKET_GOALS_SUCCESS = 'GET_MARKET_GOALS_SUCCESS';
export const GET_PROPERTY_GOALS = "GET_PROPERTY_GOALS";
export const GET_PROPERTY_GOALS_SUCCESS = 'GET_PROPERTY_GOALS_SUCCESS';

// Portfolio Analytics
export const GET_PORTFOLIO_ANALYTICS = "GET_PORTFOLIO_ANALYTICS";
export const SET_PORTFOLIO_ANALYTICS = "SET_PORTFOLIO_ANALYTICS";

// Property Groups
export const GET_PROPERTY_GROUPS = 'GET_PROPERTY_GROUPS';
export const GET_PROPERTY_GROUPS_SUCCESS = 'GET_PROPERTY_GROUPS_SUCCESS';
export const GET_PROPERTY_GROUP_BY_ID = 'GET_PROPERTY_GROUP_BY_ID';
export const GET_PROPERTY_GROUP_BY_ID_SUCCESS = 'GET_PROPERTY_GROUP_BY_ID_SUCCESS';
export const GET_PROPERTY_GROUPS_LISTINGS = 'GET_PROPERTY_GROUPS_LISTINGS';
export const GET_PROPERTY_GROUPS_LISTINGS_SUCCESS = 'GET_PROPERTY_GROUPS_LISTINGS_SUCCESS';
import React, { useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText, BodyTitle } from 'components/ReusableComponents/Text/Text';
import { currencyFormatter, getWeekNumber } from '../../../../../../../util/helper';
import { PropertyMenuDiv } from '../../../CommonCSS';
import SkLoaderOver from '../../../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import { useSelector } from 'react-redux';
import { Bar, Tooltip, ComposedChart, Line, XAxis, YAxis } from 'recharts';
import GoalLine from './PropertyOverviewGoalLine';
import { HALF_DAY_NAME, HALF_MONTH_NAME } from '../../../../../../../constants/GlobalConstant';
import PropertyOverviewGraphFilter from './PropertyOverviewGraphFilter';
import CheckBox from '../../../../../../../components/ReusableComponents/Checkbox/CheckBox';

const PropertyOverviewDiv = styled(PropertyMenuDiv)`
  display: flex;
  flex-direction: column;
`;

const MetricBody = styled.div`
  width: 100%;
  gap: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 34px 0 0 0;
`;

const MetricDiv = styled.div`
  width: 420px;
  height: 180px;
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${BodyCSS}
`;

const MetricTitle = styled(BodyText)`
  color: ${({theme}) => theme.quibbleBackgroundGrey};
`;

const MetricValue = styled(BodyTitle)`
  margin: 8px 0 0 0;
  font-size: 2rem;
  line-height: 100%;
  color: ${({metricColor}) => metricColor};
`;

const ForecastDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const ProjectedDiv = styled.div`
  width: fit-content;
  height: 30px;
  margin: 8px 0 0 0;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Commissioner;
  font-size: 16px;
  color: ${({theme}) => theme.quibbleBlue};
  font-weight: 300;

  border: 1px solid ${({theme}) => theme.quibbleBlue};
  border-style: dotted;
`;

const GoalDiv = styled(ProjectedDiv)`
  color: #EDB548;
  border: 1px solid #EDB548;
  border-style: dotted;
`;

const ChartDiv = styled.div`
  ${BodyCSS}
  width: 100%;
  height: 660px;
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ChartTitle = styled.div`
  width: 1340px;
  height: 40px;
  margin: 32px 0 8px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CheckPortionDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const CheckBoxDiv = styled(CheckPortionDiv)`
  gap: 4px;
`;

const ChartContainter = styled.div`
  width: fit-content;
  height: 460px;
`;

const ToolTipDiv = styled.div`
  ${BodyCSS};
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
`;

const TooltipText = styled(BodyText)`
  color: ${({$color}) => $color};
  font-weight: 400;
  margin: 0px;
`;

const RevenueText = styled.h1`
  width: fit-content;
  height: fit-content;
  font-size: 18px;
  position: absolute;
  color: #283334;
  top: 0px;
  bottom: 0px;
  left: 48px;
  margin: auto;
  transform: rotate(-90deg);
`;



const PropertyOverview = ({monthYear, propertyId}) => {

  const theme = useTheme();

  const [chartPeriod, setChartPeriod] = useState('monthly');
  const [showTotalRent, setShowTotalRent] = useState(false);
  const [showFee, setShowFee] = useState(false);

  const { isFetchingPropertyOverviewMetrics, propertyOverviewMetrics, propertyOverviewChart, isFetchingOverviewChart } = useSelector(({ property }) => property);

  const {forecast, metric, percentage, goalPlan} = propertyOverviewMetrics;

  const onShowTotalRent = () => {
    setShowTotalRent((prev) => !prev);
  }

  const onShowFee = () => {
    setShowFee((prev) => !prev);
  }

  const yTickFormat = (data) => {
    return currencyFormatter(data);
  }

  const xTickFormat = (data) => {

    if (isFetchingOverviewChart) {
      return 'loading'
    };
    
    if (chartPeriod === 'monthly') {
      return HALF_MONTH_NAME[data - 1 ];
    };

    if (chartPeriod === 'weekly') {
      return data + 1;
    };

    if (chartPeriod === 'daily') {
      if (data === 7) {
        return HALF_DAY_NAME[0]
      };

      return HALF_DAY_NAME[data]
    };
  };

  const maxNumber = useMemo(() => {
    return Math.max(...propertyOverviewChart.reduce((outputVal, val) => {
      const currentTotal = val.forecastRevenue > val.currentYearTotalRevenue ? val.forecastRevenue  :  val.currentYearTotalRevenue;
      const lastYearTotal = val.lastYearTotalRevenue;
      return [...outputVal, currentTotal, lastYearTotal];
    }, []));
  }, [propertyOverviewChart]);

  const addThirty = Number(((maxNumber * .3) + maxNumber).toFixed(0));



  const addGoalPropertyOverviewChart = useMemo(() =>  {
    return propertyOverviewChart.map((data) => 
      {
        const forecast = data?.forecastRevenue - data?.currentYearTotalRevenue;
        let currentRevenue = null;
        let lastRevenue = null;

        if (!showTotalRent) {
          currentRevenue = data?.currentYearTotalRent;
          lastRevenue = data?.lastYearTotalRent;
        } 

        if (!showFee) {
          currentRevenue = data?.currentYearTotalFees;
          lastRevenue = data?.lastYearTotalFees;
        } 

        if (!showTotalRent && !showFee) {
          currentRevenue = data?.currentYearTotalRevenue;
          lastRevenue = data?.lastYearTotalRevenue;
        } 

        return   ({
          ...data,
          lastRevenue,
          currentRevenue,
          forecastGraph: forecast > 0 ? forecast : null,
          goalValue: data?.goalPlans?.[0]?.revenue <= addThirty ? data?.goalPlans?.[0]?.revenue : null,
          // goalData: data?.goalPlans?.[0]?.revenue < maxDomain ? data?.goalPlans?.[0]?.revenue : null, 
        })
      }
    );
  },[propertyOverviewChart, showTotalRent, showFee, addThirty,]);

  const maxDomain = useMemo(() => {
    if (addGoalPropertyOverviewChart.some((data) => data?.goalValue <= addThirty)) {
      return addThirty;
    };

    return Number(((maxNumber * .05) + maxNumber).toFixed(0));
  }, [addGoalPropertyOverviewChart, maxNumber, addThirty]);
  
  const renderTooltip = (data) => {
    if (!data) return null;
    const {active, label} = data;
    if (!active) return null;

    const toDate = new Date();
    
    let dataElem = [];
    let checkifValidDate = false;


    if (chartPeriod === 'monthly') {
      dataElem = addGoalPropertyOverviewChart[label-1];
      checkifValidDate = new Date(monthYear.getFullYear(), label - 1) >= new Date(toDate.getFullYear(), toDate.getMonth());
    };

    if (chartPeriod === 'weekly') {
      dataElem = addGoalPropertyOverviewChart[label];

      if (monthYear.getFullYear() === toDate.getFullYear()) {
        checkifValidDate = (label + 1) >=  getWeekNumber(toDate)[1];
      }

      if (monthYear.getFullYear() > toDate.getFullYear()) {
        checkifValidDate = true;
      }

    }

    if (chartPeriod === 'daily') {
      dataElem = addGoalPropertyOverviewChart[label-1];
    };

    return (
      <ToolTipDiv>
        <TooltipText $color="#FFA800">
          Goal: {currencyFormatter(dataElem?.goalValue)}
        </TooltipText>
        <TooltipText $color="#00BBD2">
          <b>{monthYear.getFullYear()}:</b>
        </TooltipText>
        {checkifValidDate ? <TooltipText $color="#00BBD2">
          Forecast: {currencyFormatter(dataElem?.forecastRevenue)}
        </TooltipText> : null}
        <TooltipText $color="#00BBD2">
          Total Revenue: {currencyFormatter(dataElem?.currentYearTotalRevenue)}
        </TooltipText>
          {showTotalRent && <TooltipText $color="#82C928">
          Total Rent: {currencyFormatter(dataElem?.currentYearTotalRent)}
        </TooltipText>}
          {showFee && <TooltipText $color="#008981">
          Fees: {currencyFormatter(dataElem?.currentYearTotalFees)}
        </TooltipText>}
        <TooltipText $color="#C6A1F5">
          <b>{monthYear.getFullYear() - 1}:</b>
        </TooltipText>
        <TooltipText $color="#C6A1F5">
          Total Revenue: {currencyFormatter(dataElem?.lastYearTotalRevenue)}
        </TooltipText>
          {showTotalRent && <TooltipText $color="#82C928">
          Total Rent: {currencyFormatter(dataElem?.lastYearTotalRent)}
        </TooltipText>}
          {showFee && <TooltipText $color="#008981">
          Fees: {currencyFormatter(dataElem?.lastYearTotalFees)}
        </TooltipText>}
      </ToolTipDiv>
    )
  };

  return (
    <PropertyOverviewDiv>
      <BodyTitle>
        Q-Insights
      </BodyTitle>
      <MetricBody>
        <MetricDiv>
          <MetricTitle>
            Revenue
          </MetricTitle>
          <MetricValue metricColor={theme.quibbleBlue}>
            {metric?.totalRevenue ? currencyFormatter(metric.totalRevenue) : '--'}
          </MetricValue>
          <MetricTitle margin="8px 0 0 0">
            {percentage?.revenue ? `${(percentage?.revenue * 100).toFixed(0)}%` : '--'}
          </MetricTitle>
          <ForecastDiv>
            <ProjectedDiv>
              Projected: {forecast?.revenue ? currencyFormatter(forecast.revenue) : '--'}
            </ProjectedDiv>
            {goalPlan?.revenue ? <GoalDiv>
              Goal: { currencyFormatter(goalPlan.revenue)}
            </GoalDiv> : null}
          </ForecastDiv>
          <SkLoaderOver isLoading={isFetchingPropertyOverviewMetrics} />
        </MetricDiv>
        <MetricDiv>
          <MetricTitle>
            Total Rent
          </MetricTitle>
          <MetricValue metricColor='#FA6DFD'>
            {metric?.totalRent ? currencyFormatter(metric.totalRent) : '--'}
          </MetricValue>
          <MetricTitle margin="8px 0 0 0">
            {percentage?.rent ? `${(percentage?.rent * 100).toFixed(0)}%` : '--'}
          </MetricTitle>
          <ForecastDiv>
            <ProjectedDiv>
              Projected: {forecast?.rent ? currencyFormatter(forecast.rent) : '--'}
            </ProjectedDiv>
            {goalPlan?.rent ? <GoalDiv>
              Goal: {currencyFormatter(goalPlan.rent)}
            </GoalDiv> : null}
          </ForecastDiv>
          <SkLoaderOver isLoading={isFetchingPropertyOverviewMetrics} />
        </MetricDiv>
        <MetricDiv>
          <MetricTitle>
              ADR
            </MetricTitle>
            <MetricValue metricColor={theme.quibblePurple}>
              {metric?.adr ? currencyFormatter(metric.adr) : '--'}
            </MetricValue>
            <MetricTitle margin="8px 0 0 0">
              {percentage?.adr ? `${(percentage?.adr * 100).toFixed(0)}%` : '--'}
            </MetricTitle>
            <ForecastDiv>
              <ProjectedDiv>
                Projected: {forecast?.adr ? currencyFormatter(forecast.adr) : '--'}
              </ProjectedDiv>
              {goalPlan?.adr ? <GoalDiv>
                Goal: { currencyFormatter(goalPlan.adr)}
              </GoalDiv> : null}
            </ForecastDiv>
          <SkLoaderOver isLoading={isFetchingPropertyOverviewMetrics} />
        </MetricDiv>
        <MetricDiv>
          <MetricTitle>
            Occupancy
          </MetricTitle>
          <MetricValue metricColor={theme.quibbleYellow}>
            {metric?.occ ? `${(metric.occ * 100).toFixed(0)}%` : '--'}
          </MetricValue>
          <MetricTitle margin="8px 0 0 0">
            {percentage?.occ ? `${(percentage?.occ * 100).toFixed(0)}%` : '--'}
          </MetricTitle>
          <ForecastDiv>
            <ProjectedDiv>
              Projected: {forecast?.occ ? `${(forecast.occ * 100).toFixed(0)}%` : '--'}
            </ProjectedDiv>
            {goalPlan?.occupancy ? <GoalDiv>
                Goal:  { `${(goalPlan.occupancy * 100).toFixed(0)}%`}
            </GoalDiv> : null}
          </ForecastDiv>
          <SkLoaderOver isLoading={isFetchingPropertyOverviewMetrics} />
        </MetricDiv>
      </MetricBody>
      
      {/* <ChartContainer {...totalRevenueChart} year={year} columns={1}/> */}
     
      <ChartDiv>
        <RevenueText>
          Revenue
        </RevenueText>
        <ChartTitle>
            <BodyTitle>
              Total Revenue
            </BodyTitle>
            <CheckPortionDiv>
              <BodyText margin="0px">
                Show:
              </BodyText>
              <CheckBoxDiv>
                <CheckBox checked={showTotalRent} onCheckBoxChange={onShowTotalRent}/>
                <BodyText margin="0px">
                  Total Rent
                </BodyText>
              </CheckBoxDiv>
              <CheckBoxDiv>
                <CheckBox checked={showFee} onCheckBoxChange={onShowFee}/>
                <BodyText margin="0px">
                  Fees
                </BodyText>
              </CheckBoxDiv>
            </CheckPortionDiv>
        </ChartTitle>
        <PropertyOverviewGraphFilter chartPeriod={chartPeriod} propertyId={propertyId} year={monthYear.getFullYear()} setChartPeriod={setChartPeriod}/>
        <ChartContainter>
          <ComposedChart
            width={1458}
            height={460}
            data={addGoalPropertyOverviewChart}
            margin={{
              top: -29,
              left: -1
            }}
            {...{
              overflow: 'visible'
            }}
          >
            <XAxis
             dataKey="key" 
             stroke="#D9D9D9"
             tickLine={false}  
             tick={{stroke: '#283334', fontWeight: 300, strokeWidth: 0.5}}
             tickFormatter={xTickFormat}
            />
            <XAxis domain={[0, 12]} xAxisId="top" orientation="top" tick={false} stroke="#D9D9D9"/>
            <YAxis  
              domain={[0, maxDomain]} 
              stroke="#D9D9D9"
              margin={{left: 10}}
              tickLine={false}  
              tick={{stroke: '#283334', fontWeight: 300, strokeWidth: 0.5}}
              padding={{top: 10}}
              tickFormatter={yTickFormat}
            />
            <YAxis 
              domain={[0, maxDomain]} 
              yAxisId="right" 
              orientation="right" 
              type="number" 
              tick={false}
              stroke="#D9D9D9"  
            />

            <Tooltip content={renderTooltip} />
            <Bar hide={!showFee} dataKey="lastYearTotalFees" barSize={24} stackId="a" fill="#008981" />
            <Bar hide={!showTotalRent} dataKey="lastYearTotalRent" barSize={24} stackId="a" fill="#82C928" />
            <Bar dataKey="lastRevenue" barSize={24} stackId="a" fill="#C6A1F5" />

            <Bar hide={!showFee} dataKey="currentYearTotalFees" barSize={24} stackId="b" fill="#008981" />
            <Bar hide={!showTotalRent} dataKey="currentYearTotalRent" barSize={24} stackId="b" fill="#82C928" />
            <Bar dataKey="currentRevenue" barSize={24} stackId="b" fill="#2ECADB" />
            <Bar dataKey="forecastGraph" barSize={24} stackId="b" fill="#99E7EE" />
            <Line type="monotone" dot={<GoalLine chartPeriod={chartPeriod}/>} dataKey="goalValue" stroke="#FF94F4" strokeWidth="0" connectNulls={true}/>

          </ComposedChart>
        </ChartContainter>
      </ChartDiv>
    </PropertyOverviewDiv>
  )
}

export default PropertyOverview;

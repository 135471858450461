import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider, Modal, message, Select, Checkbox, Tooltip, Menu, Dropdown } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

import { PrimaryButton } from '../../../components/ReusableComponents/Button/Button';
import { getPropertyGroups, getPropertyGroupsListings } from '../../../appRedux/actions/Property';
import { property } from '../../../services/property';
import searchIcon from 'assets/images/generic/search.svg';
import GroupTable from './GroupTable';

import { BodyText } from "components/ReusableComponents/Text/Text";
import { NestedComponent, GroupComponent } from './NestedAndGroup';

const { confirm } = Modal;
const { Option } = Select;

const PropertyGroupsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NewGroupContainer = styled.div`
  min-height: calc(100vh - 350px);
  margin: 25px 0;
  overflow: auto;

  .ant-space-item {
    margin-bottom: 16px;
  }

  .ant-form-horizontal .ant-row {
    margin: 0;
  }

  .ant-btn-icon-only {
    margin: 0;
  }

  .ant-space-item {
    margin-bottom: 0;
  }
`;

const InfoContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
`;

const HoverableSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #00BBD2;
  }
`;

const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-placement-top .ant-tooltip-content .ant-tooltip-arrow {
    display: none !important;
  }
`;

const ViewModeBody = styled.div`
  background: #fff;
  #quibbleTable {
    min-height: 0;
  }
`;

const NewGroupContainerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const SearchContainer = styled.div`
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const AddGroupButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AddGroupButton = styled(PrimaryButton)`
  height: 50px;
`;

const SearchInput = styled.input`
  width: 300px;
  height: 48px;
  padding: 0 0 0 8px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  font-size: ${({ theme }) => theme.s4};

  ::placeholder {
    color: #B0B0B0;
    opacity: 1;
  }
`;

const SearchImage = styled.img`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 8px;
  margin: auto;
`;


const SelectContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const MarketSelect = styled(Select)`
  width: 400px;
  height: 48px;
  font-size: ${({theme}) => theme.s4};
`;

const TypeSelect = styled(Select)`
  margin-right: 20px;
  height: 48px;
  width: 150px;
  font-size: ${({theme}) => theme.s4};
`;

const SwitchDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  margin: 0 8px;
`;



const PropertyGroups = () => {
  const { propertyGroups, propertyGroupsListings } = useSelector(({ property }) => property);

  const dispatch = useDispatch();
  
  const [selectMarket, setSelectMarket] = useState([]);

  const [mode, setMode] = useState('view');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedListings, setSelectedListings] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [transformedPropertyGroups, setTransformedPropertyGroups] = useState([]);
  const [groupType, setGroupType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [parentId, setParentId] = useState('');
  const [editGroupId, setEditGroupId] = useState(null);

  const [typeFilter, setTypeFilter] = useState('');
  const [marketFilter, setMarketFilter] = useState('');
  const [expandAllRows, setExpandAllRows] = useState(false);
  const [showActiveOnly, setShowActiveOnly] = useState(false);

  const [childProperties, setChildProperties] = useState(['']);
  const [listingsMap, setListingsMap] = useState({});
  const [currentPropertyGroupData, setCurrentPropertyGroupData] = useState([]);

  const [groupName, setGroupName] = useState('');
  const [sameBuilding, setSameBuilding] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);


  useEffect(() => {
    dispatch(getPropertyGroups());
    dispatch(getPropertyGroupsListings());
  }, [dispatch]);

  useEffect(() => {
    if (propertyGroupsListings.length) {
      const markets = propertyGroupsListings.reduce((outputData, currentData) => {
        if (outputData.includes(currentData.city) || !currentData.city) return outputData;
        return [...outputData, currentData.city]
      }, []);

      setSelectMarket(markets);
    }
    
  }, [propertyGroupsListings]);

  useEffect(() => {
    console.log(propertyGroups)
    if (propertyGroups) {
      const data = propertyGroups.map((group) => ({
        id: group?._id ?? 'N/A',
        groupName: group?.name ?? 'N/A',
        type: group?.type ?? 'N/A',
        isSameLocation: group.isSameLocation,
        listings: group?.listings?.map((listing) => ({
          id: listing?.id ?? 'N/A',
          name: listing?.name ?? 'N/A',
          city: listing?.city ?? 'N/A',
          propertyGroupTags: listing?.propertyGroupTags?.join(', ') ?? '',
          propertyGroupParent: listing?.propertyGroupParent ?? false,
          active: listing?.active ?? false,
        })) ?? [],
      }));
      setTransformedPropertyGroups(data);
    }
  }, [propertyGroups]);

  const resetForm = () => {
    setMode('view');
    setEditGroupId(null);
    setParentId('');
    setChildProperties(['']);
    setListingsMap({});
    setGroupName('');
    setSameBuilding(false);
    setUploadedImage(null);
  };
  

  const handleDeleteGroup = async (propertyGroupId) => {
    try {
      const res = await property.deletePropertyGroup(propertyGroupId);

      if (res.status === 200 || res.status === 201) {
        dispatch(getPropertyGroups());
        message.success('Property group deleted successfully.');
      } else {
        message.error('Failed to delete property group.');
      }
    } catch (error) {
      message.error('An error occurred while deleting the property group.');
    }
  };

  const fetchPropertyGroupById = async (groupId) => {
    try {
      const data = await property.getPropertyGroupById({ id: groupId });

      if (!data || !Array.isArray(data.listings)) {
        message.error('Failed to load property group data.');
        return;
      }

      setMode(data.type === 'Group' ? 'group' : 'nested');

      setGroupName(data.name || '');
      setSameBuilding(data.isSameLocation || false);
      setUploadedImage(data.imageUrl || null);

      const parentListing = data.listings.find((listing) => listing.propertyGroupParent);
      setParentId(parentListing ? parentListing.id : '');
      const childListings = data.listings
        .filter((listing) => !listing.propertyGroupParent)
        .map((listing) => listing.id);
      setChildProperties(childListings.length > 0 ? childListings : ['']);

      const listingsMap = data.listings.reduce((acc, listing) => {
        acc[listing.id] = listing.name;
        return acc;
      }, {});
      setListingsMap(listingsMap);

      setEditGroupId(groupId);
    } catch (error) {
      message.error('Failed to load property group data.');
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const showDeleteConfirm = (propertyGroupId) => {
    confirm({
      title: `Are you sure delete this group?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteGroup(propertyGroupId);
        resetForm();
      },
    });
  };

  const filteredPropertyGroups = transformedPropertyGroups
    .filter((group) => group.groupName.toLowerCase().includes(searchText.toLowerCase()))
    .filter((group) => (typeFilter ? group.type === typeFilter : true))
    .filter((group) => (marketFilter ? group.listings.some(listing => listing.city === marketFilter) : true))
    .filter((group) => (showActiveOnly ? group.listings.some(listing => listing.active) : true));


  const AddGroupMenu = () => (
    <Menu>
      <Menu.Item key="1" onClick={() => setMode('nested')}>
        Nested
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setMode('group')}>
        Group
      </Menu.Item>
    </Menu>
  );

  const handleChildChange = (value, index) => {
    const updatedChildren = [...childProperties];
    updatedChildren[index] = value;
    setChildProperties(updatedChildren);
  };

  const handleAddChild = () => setChildProperties([...childProperties, '']);
  const handleDeleteChild = (index) => {
    const updatedChildren = [...childProperties];
    updatedChildren.splice(index, 1);
    setChildProperties(updatedChildren);
  };

  const handleCancel = () => {
    resetForm();
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (mode === 'group' && !groupName.trim()) {
        message.error('Group name is required.');
        setIsLoading(false);
        return;
      }

      if (childProperties.some((child) => !child)) {
        message.error('Please ensure all child properties are selected.');
        setIsLoading(false);
        return;
      }

      const listingsData = childProperties.map((childId) => ({ id: childId }));
      if (mode === 'nested' && parentId) {
        listingsData.unshift({ id: parentId, parent: true });
      }

      const body = {
        name: groupName.trim(),
        type: mode === 'nested' ? 'Nested' : 'Group',
        isSameLocation: mode === 'group' ? sameBuilding : undefined,
        imageUrl: mode === 'group' ? uploadedImage : undefined,
        listings: listingsData,
      };

      let response;
      if (editGroupId) {
        response = await property.updatePropertyGroup({ id: editGroupId, body });
      } else {
        response = await property.createPropertyGroup({ body });
      }

      if (response.status === 200 || response.status === 201) {
        message.success(`Property group ${editGroupId ? 'updated' : 'created'} successfully.`);
        dispatch(getPropertyGroups());
        resetForm();
      } else {
        message.error(`Failed to ${editGroupId ? 'update' : 'create'} property group.`);
      }
    } catch (error) {
      message.error(`An error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  const renderContent = () => {
    if (mode === 'nested') {
      return (
        <NestedComponent
          parentId={parentId}
          childProperties={childProperties}
          listingsMap={listingsMap}
          propertyGroupsListings={propertyGroupsListings}
          onParentChange={setParentId}
          onChildChange={handleChildChange}
          onAddChild={handleAddChild}
          onDeleteChild={handleDeleteChild}
          onCancel={handleCancel}
          onSave={handleSave}
          onUngroup={() => showDeleteConfirm(editGroupId)}
          isEditMode={!!editGroupId}
        />
      );
    }

    if (mode === 'group') {
      return (
        <GroupComponent
          onCancel={handleCancel}
          onSave={handleSave}
          groupName={groupName}
          setGroupName={setGroupName}
          sameBuilding={sameBuilding}
          setSameBuilding={setSameBuilding}
          uploadedImage={uploadedImage}
          setUploadedImage={setUploadedImage}
          childProperties={childProperties}
          listingsMap={listingsMap}
          propertyGroupsListings={propertyGroupsListings}
          onChildChange={handleChildChange}
          onAddChild={handleAddChild}
          onDeleteChild={handleDeleteChild}
          onUngroup={() => showDeleteConfirm(editGroupId)}
          isEditMode={!!editGroupId}
        />
      );
    }
    
    return (
      <>
        <InfoContainer>
          What are&nbsp;
          <StyledTooltip
            title="This refers to a group of listings that can be sold as an entire unit, or in separate listings. The Parent listings include all of the listings, and the Child listings are all of the individual listings."
          >
            <HoverableSpan>Nesting</HoverableSpan>
          </StyledTooltip>
          &nbsp;and&nbsp;
          <StyledTooltip
            title="These are groups of properties that will allow you to have the same settings and rules applied at this level. They will also have data aggregated for analytics."
          >
            <HoverableSpan>Groupings</HoverableSpan>
          </StyledTooltip>
          {' ?'}
        </InfoContainer>
        <ViewModeBody>
          <NewGroupContainerHeader>
            <SelectContainer>
              <TypeSelect
                placeholder="Select Market"
                value={typeFilter}
                onChange={(value) => setTypeFilter(value)}
              >
                <Option key={'All'} value={''}>All</Option>
                <Option value="Nested">Nested</Option>
                <Option value="Group">Group</Option>
              </TypeSelect>
              {/* <MarketSelect value={marketFilter} placeholder="Select Market" onChange={(value) => setMarketFilter(value)}>
                <Option key={'All Markets'} value={''}>All Markets</Option>
                {selectMarket.map((data) => (<Option key={data} value={data}>{data}</Option>))}
              </MarketSelect> */}
            </SelectContainer>
            <SwitchDiv>
              <Checkbox
                checked={expandAllRows}
                onChange={(e) => setExpandAllRows(e.target.checked)}
              >
                <BodyText>Expand All</BodyText>
              </Checkbox>
            </SwitchDiv>
            <SwitchDiv>
              <Checkbox
                checked={showActiveOnly}
                onChange={(e) => setShowActiveOnly(e.target.checked)}
              >
                <BodyText>Show Active</BodyText>
              </Checkbox>
            </SwitchDiv>
            <SearchContainer>
              <SearchInput
                type="search"
                placeholder="Search"
                value={searchText}
                onChange={handleSearchChange}
              />
              <SearchImage src={searchIcon} alt="search icon" />
            </SearchContainer>
            <AddGroupButtonContainer>
              <Dropdown overlay={<AddGroupMenu />} trigger={['click']} placement="bottomRight">
                <AddGroupButton>
                  <PlusOutlined />&nbsp;New
                </AddGroupButton>
              </Dropdown>
            </AddGroupButtonContainer>
          </NewGroupContainerHeader>
          <Divider />
          <GroupTable
            filteredPropertyGroups={filteredPropertyGroups}
            showDeleteConfirm={showDeleteConfirm}
            onEditGroup={fetchPropertyGroupById}
            expandAllRows={expandAllRows}
          />
        </ViewModeBody>
      </>
    )
  }

  return (
    <PropertyGroupsContainer>
      <NewGroupContainer>
        {renderContent()}
      </NewGroupContainer>
    </PropertyGroupsContainer>
  );
};

export default PropertyGroups;

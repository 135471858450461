import React, { useRef } from 'react';
import styled from 'styled-components';
import { LayoutBody } from '../../../../components/ReusableComponents/Layout/LayoutBody';
import { darkScrollbar, lightScrollbar } from '../../../../components/ReusableComponents/Scrollbar/Scrollbar';
// import ReportHeader from './ReportHeader';
import ReportPDF from './ReportPDF';

const PropertyScoutReportBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: ${({justification}) => justification};
  // justify-content: ${({justification}) => justification};
  justify-content: flex-start;
  overflow: auto;
  padding: 12px;

  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;


const PropertyScoutReport = () => {

  const componentRef = useRef(null);
  
  return (
    <PropertyScoutReportBody>  
        {/* <ReportHeader componentRef={componentRef} /> */}
        <ReportPDF ref={componentRef} /> 
    </PropertyScoutReportBody>
  )
}

export default PropertyScoutReport;